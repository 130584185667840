<template>
    <v-card class="pa-5"
        v-on:dragenter.prevent="handleDragEnter"
        v-on:dragover.prevent="handleDragOver"
        v-on:dragleave.prevent="handleDragLeave"
        v-on:drop.prevent="handleDrop"
        v-on:mouseout.prevent="handleMouseOut"
        v-on:dragend.prevent="resetDragState"
     >
        <FilePreview ref="filePreview" :session="session" :client_id="clientId" @showError="(arg) => $emit('showError', arg)" @showInfo="(arg) => $emit('showInfo', arg)" @getBucketFiles="getBucketFiles" />

        <div class="d-flex align-center mb-5">
            <div class="text-h6">
                Dokumente
            </div>
            <slot></slot>
        </div>

        <v-row no-gutters v-if="sortedAllUploadedFiles.length > 0 && !dragOver">
            <v-col cols="12" :sm="smCols" :md="mdCols" class="d-flex">
                <v-hover v-slot="{ hover }">
                    <v-card class="d-flex align-center px-3 py-1 ma-2 file-card flex-grow-1" @click="selectFiles" :elevation="hover ? 2 : 0" outlined :disabled="error_retrieving_files || retrievingFiles">
                        <v-icon class="mr-3" :color="$store.state.theme.green">mdi-cloud-upload-outline</v-icon>
                        <div class="file-info-container d-flex flex-column justify-center flex-grow-1">
                                <span class="text-body-2 font-weight-bold text-truncate-file">Dateien auswählen</span>
                                <span class="text-caption">
                                    PDF, Bilder, etc. (max. 100MB)
                                </span>
                            </div>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col v-for="(file) in sortedAllUploadedFiles" :key="'all-' + file.id" cols="12" :sm="smCols" :md="mdCols">
                <v-hover v-slot="{ hover }">
                    <v-card class="d-flex align-center px-3 py-1 ma-2 file-card" :elevation="hover ? 2 : 0" outlined @click.stop="openFile(file)" :disabled="file.uploading">
                        <v-icon v-if="!file.uploading" class="mr-3" :color="file.iconColor">
                            {{ file.icon}}
                        </v-icon>
                        <v-progress-circular v-else class="mr-3" :size="30" indeterminate color="primary" />
                        <div class="file-info-container d-flex flex-column justify-center flex-grow-1">
                            <span class="text-body-2 text-truncate-file">{{ file.name }}</span>
                            <span class="text-caption">
                                {{ file.size }}
                                <v-chip v-if="file.appointment_id && getDateFromAppointmentId(file.appointment_id)" class="ml-2" small>
                                    <v-icon left small>mdi-sofa-single-outline</v-icon> {{ getDateFromAppointmentId(file.appointment_id) }}
                                </v-chip>
                            </span>
                        </div>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>

        <v-card v-if="(sortedAllUploadedFiles.length === 0 || dragOver) && !retrievingFiles && !error_retrieving_files" class="pa-5 mt-3 upload-area" outlined>
            <div v-if="!dragOver" class="d-flex flex-column">
                <v-icon large color="primary">mdi-cloud-upload-outline</v-icon>
                <span class="mt-3">Ziehe Dokumente, Bilder, oder PDFs hierher um sie hochzuladen.</span>
                <span>Oder wähle die Dateien aus um sie hochzuladen.</span>


                <v-btn @click="selectFiles" outlined dense :disabled="error_retrieving_files || retrievingFiles" class="mt-3 align-self-center">
                    <v-icon left>mdi-plus</v-icon>
                    Dateien auswählen
                </v-btn>
            </div>
            <div v-else class="d-flex flex-column py-5 text-h6">
                <v-icon large :color="$store.state.theme.green">mdi-rocket-launch-outline</v-icon>
                <span class="mt-3">Lass einfach los.</span>
            </div>
        </v-card>
        <div v-else-if="error_retrieving_files">
            <v-icon left>mdi-connection</v-icon>
            Die Dokumente konnten nicht geladen werden.<br/>
            <v-btn outlined :color="$store.state.theme.green" class="mt-5" @click="getBucketFiles">
                <v-icon left>
                    mdi-refresh
                </v-icon>
                Neu Laden
            </v-btn>
        </div>
        <input type="file" ref="fileInput" hidden multiple @change="handleFiles" />
    </v-card>
</template>

<script>
import connector from '../helpers/supabase-connector.js';
import cipher from '../helpers/cipher.js';
import dayjs from 'dayjs';
import FilePreview from '../components/FilePreview.vue';

export default {
    name: 'UploadedFilesCard',
    components: {
        FilePreview
    },
    emits: [
        'showError', 
        'showInfo',
        'update:uploadedAppointmentFiles',
        'updatedFiles'
    ],
    props: {
        session: {
            type: Object,
            required: true
        },
        clientId: {
            type: Number,
            required: true
        },
        smCols : {
            type: Number,
            default: () => 6
        },
        mdCols : {
            type: Number,
            default: () => 6
        }
    },
    data() {
        return {
            dragCounter: 0,
            retrievingFiles: false,
            error_retrieving_files: false,
            dragOver: false,
            uploadedFiles: [],
            uploadedAppointmentFiles: [],
            selected_appointment_id: null,
            appointments: [],
        }
    },

    watch: {
        clientId: {
            immediate: true,
            handler(newClientId, oldClientId) {
                if (newClientId !== oldClientId) {
                    this.getBucketFiles();
                }
            }
        }
    },

    mounted() {
        // Reset drag state if the drag operation ends anywhere in the window
        window.addEventListener('dragend', this.resetDragState);
    },
    beforeUnmount() {
        window.removeEventListener('dragend', this.resetDragState);
    },

    methods: {

        emitUploadedFiles() {
            this.$emit('update:uploadedAppointmentFiles', this.uploadedAppointmentFiles);
            this.$emit('updatedFiles');
        },

        selectFilesForAppointment(appointmentId) {
            this.selected_appointment_id = appointmentId;
            this.$refs.fileInput.click();
        },

        async getBucketFiles() {

            this.retrievingFiles = true;
            this.error_retrieving_files = false;
            this.uploadedFiles = [];
            this.uploadedAppointmentFiles = [];

            // list all the files in the documentation folder of the selected client
            let client_id_folder = this.clientId + '/'
            let files = await connector.listFilesInBucket(this, 'documentation', this.session.user.id + '/' + client_id_folder);
            if (files === -1) {
                // error has already been displayed
                // set error variable and continue normally
                this.error_retrieving_files = true;
                files = []; // set to empty array from -1
            }

            this.uploadedFiles = await Promise.all(
                files
                    .filter(file => file.id !== null && file.name !== '.emptyFolderPlaceholder')
                    .map(async file => {
                        let displayName = file.name;
                        const storageName = file.name;
                        // If the file name is encrypted, decrypt it
                        if (displayName.startsWith("enc:1:") && this.$store.state.aes_key_file) {
                            try {
                                displayName = await cipher.decryptFileName(this, this.$store.state.aes_key_file, displayName);
                            } catch (e) {
                                // In case of error, log it and fallback to the encrypted name
                                console.error('Fehler beim Entschlüsseln des Dateinamens:', e);
                            }
                        }
                        return {
                            id: file.id,
                            created_at: file.created_at,
                            updated_at: file.updated_at,
                            name: displayName,
                            storageName: storageName,
                            size: this.bytesToSize(file.metadata.size),
                            icon: this.getFileIcon(file.metadata.mimetype),
                            mimetype: file.metadata.mimetype,
                            iconColor: this.getIconColor(file.metadata.mimetype),
                            uploading: false,
                        };
                    })
            );

            // get the files which are uploaded to subfolders of the client folder, which are the appointment ids which they belong to
            let uploadedAppointmentPaths = files.filter(file => file.id === null && file.name !== '.emptyFolderPlaceholder');
            
            for (const path of uploadedAppointmentPaths) {
                let appointment_id = path.name;
                let appointment_files = await connector.listFilesInBucket(this, 'documentation', this.session.user.id + '/' + client_id_folder + appointment_id + '/');
                if (appointment_files === -1) {
                    // error has already been shown
                    // stop the loop, and clear all files and prompt reload dialog
                    this.uploadedFiles = [];
                    this.uploadedAppointmentFiles = [];
                    this.error_retrieving_files = true;
                    break;
                }

                // concat the appointment files to the uploadedAppointmentFiles array
                this.uploadedAppointmentFiles = await Promise.all(
                    this.uploadedAppointmentFiles.concat(appointment_files.filter(file => file.id !== null).map(async file => {
                        let displayName = file.name;
                        const storageName = file.name;
                        // If the file name is encrypted, decrypt it
                        if (displayName.startsWith("enc:1:") && this.$store.state.aes_key_file) {
                            try {
                                displayName = await cipher.decryptFileName(this, this.$store.state.aes_key_file, displayName);
                            } catch (e) {
                                // In case of error, log it and fallback to the encrypted name
                                console.error('Fehler beim Entschlüsseln des Dateinamens:', e);
                            }
                        }
                        return {
                            id: file.id,
                            appointment_id: parseInt(appointment_id),
                            created_at: file.created_at,
                            updated_at: file.updated_at,
                            name: displayName,
                            storageName: storageName,
                            size: this.bytesToSize(file.metadata.size),
                            icon: this.getFileIcon(file.metadata.mimetype),
                            mimetype: file.metadata.mimetype,
                            iconColor: this.getIconColor(file.metadata.mimetype),
                            uploading: false,
                        }
                    }))
                );
            }

            if (this.uploadedAppointmentFiles.length > 0 && this.appointments.length === 0) {
                // retrieve the dates for the appointments.
                let appointments = await connector.getDataOnlyFiltered(this, 'termine', 'eq', 'fk_klienten_id', this.clientId, 'id', true, 'id, datum');
                if (appointments === -1) {
                    // error has already beeen shown
                    appointments = [];
                }
                this.appointments = appointments;
            }

            this.emitUploadedFiles();
            this.retrievingFiles = false;
        },

        async updateAppointments() {
            let appointments = await connector.getDataOnlyFiltered(this, 'termine', 'eq', 'fk_klienten_id', this.clientId, 'id', true, 'id, datum');
            if (appointments === -1) {
                // error has already been shown
                appointments = [];
            }
            this.appointments = appointments;
        },

        bytesToSize(bytes) {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
            if (bytes === 0) return '0 Byte'
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
        },

        selectFiles() {
            this.$refs.fileInput.click();
        },

        handleDragEnter(e) {
            e.preventDefault();
            this.dragCounter++;
            if (this.dragCounter === 1) {
                this.dragOver = true;
            }
        },

        handleDragOver(e) {
            e.preventDefault();
        },

        handleDragLeave(e) {
            e.preventDefault();
            this.dragCounter--;
            if (this.dragCounter === 0) {
                this.dragOver = false;
            }
        },

        handleDrop(e) {
            e.preventDefault();
            this.resetDragState();
            this.handleFiles(e);
        },

        handleMouseOut(e) {
            // Reset state if mouse leaves the drop zone entirely
            if (!e.relatedTarget || !this.$el.contains(e.relatedTarget)) {
                this.resetDragState();
            }
        },

        resetDragState() {
            this.dragCounter = 0;
            this.dragOver = false;
        },

        getDateFromAppointmentId(appointment_id, return_as_dayjs = false) {
            // format it to DD.MM.YYYY
            let appointment = this.appointments.find(appointment => appointment.id === appointment_id);
            if (appointment && appointment.datum) {
                if (return_as_dayjs) return dayjs(appointment.datum);
                else return dayjs(appointment.datum).format('DD.MM.YYYY');
            } else {
                return null
            }
        },

        async handleFiles(event) {
            const files = event.target.files || event.dataTransfer.files;
            try {
                let uploaded = await this.uploadFilesToSupabase(files);
                if (uploaded.length === 0 || uploaded.some((file) => file === null)) {
                    await connector.logError(this, {
                        uid: this.session.user.id,
                        message: 'LOG: Possible error during file upload in documentation.',
                    });
                    // some uploads or all failed, so reload the files. 
                    this.$nextTick(() => {
                        this.getBucketFiles();
                    });
                } else {
                    // all good.
                    this.emitUploadedFiles();

                    // in case the file was uploaded to an appointment and we have not loaded them yet, load them.
                    if (this.selected_appointment_id && this.appointments.length === 0) {
                        await this.updateAppointments();
                    }
                    
                    this.$emit('showInfo', {
                        message: uploaded.length === 1 ? 'Die Datei wurde erfolgreich hochgeladen.' : 'Die Dateien wurden erfolgreich hochgeladen.',
                        timeout: 5000
                    });
                }
            } catch (error) {
                this.$emit('showError', {
                    message: 'Ein Fehler ist beim Hochladen der Dateien aufgetreten. Bitte versuche es erneut.',
                    timeout: 10000,
                    error: error
                });
                this.$nextTick(() => {
                    this.getBucketFiles();
                });
            } finally {
                this.resetFileInput();
            }
        },

        resetFileInput() {
            if (this.$refs.fileInput) {
                this.$refs.fileInput.value = '';
            }
            this.selected_appointment_id = null;
        },

        async uploadFilesToSupabase(files) {
            if (files.length === 0) return;

            let client_id_folder = this.clientId + '/';
            if (this.selected_appointment_id !== null) {
                client_id_folder = this.clientId + '/' + this.selected_appointment_id + '/';
            }

            let newUploadedFiles = [];

            // check if aes_key_file is set, if not then try to load it again
            if (!this.$store.state.aes_key_file) {
                let keys = await cipher.getAESKeys(this);
                this.$store.state.aes_key_file = keys['aes_key_file'];
            }

            for (const file of files) {

                let file_name = file.name;
                let duplicate_files = [];
                // check if the file already exists in the folder, if so, add a number to the file name
                if (this.selected_appointment_id !== null) {
                    duplicate_files = this.uploadedAppointmentFiles.filter(f => f.name === file_name);
                } else {
                    duplicate_files = this.uploadedFiles.filter(f => f.name === file_name);
                }
                
                if (duplicate_files.length > 0) {
                    let i = 1; // Start with 1 and increment this number until a unique file name is found
                    let newName = file_name;
                    let baseName, extension;

                    // Check if the file has an extension
                    if (file_name.includes('.')) {
                        baseName = file_name.substring(0, file_name.lastIndexOf('.'));
                        extension = file_name.substring(file_name.lastIndexOf('.'));
                    } else {
                        baseName = file_name;
                        extension = '';
                    }

                    if (this.selected_appointment_id !== null) {
                        while (this.uploadedAppointmentFiles.some(f => f.name === newName)) {
                            newName = `${baseName} (${i})${extension}`;
                            i++;
                        }
                    } else {
                        // Loop until a unique file name is found
                        while (this.uploadedFiles.some(f => f.name === newName)) {
                            newName = `${baseName} (${i})${extension}`;
                            i++;
                        }
                    }

                    file_name = newName; // Assign the unique file name
                }

                let originalFileName = file_name; // save plain file name for the UI placeholder

                // Encrypt the file name if the encryption key is available
                if (this.$store.state.aes_key_file) {
                    try {
                        file_name = await cipher.encryptFileName(this, this.$store.state.aes_key_file, file_name);
                    } catch (e) {
                        this.$emit('showError', {
                            message: 'Fehler beim Verschlüsseln des Dateinamens. Bitte versuche es erneut.',
                            error: e
                        });
                        continue; // Skip this file if encryption fails
                    }
                }

                // Create a unique placeholder ID
                const placeholderId = 'placeholder_' + Date.now() + '_' + Math.random().toString(36).slice(2, 11);

                // Add template file to uploadedFiles so that the user can see the progress
                if (this.selected_appointment_id === null) {
                    this.uploadedFiles.unshift({
                        id: placeholderId,
                        name: originalFileName,
                        uploading: true,
                        size: 'wird hochgeladen...'
                    });
                } else {
                    this.uploadedAppointmentFiles.unshift({
                        id: placeholderId,
                        name: originalFileName,
                        appointment_id: this.selected_appointment_id,
                        uploading: true,
                        size: 'wird hochgeladen...'
                    });
                }

                try {
                    const reader = new FileReader();
                    const fileData = await new Promise((resolve, reject) => {
                        reader.onload = (e) => resolve(e.target.result);
                        reader.onerror = reject;
                        reader.readAsArrayBuffer(file);
                    });

                    let encrypted_file = await cipher.encryptFile(this.$store.state.aes_key_file, fileData);
                    let fileSize = file.size > 1024 * 1024 ? (file.size / (1024 * 1024)).toFixed(0) + ' MB' : (file.size / 1024).toFixed(0) + ' KB';

                    const fileDataJSON = JSON.stringify({
                        iv: encrypted_file.iv,
                        file: encrypted_file.file,
                        size: fileSize,
                    });

                    const fType = file.type ? { type: file.type } : { type: 'application/octet-stream' };
                    const blob = new Blob([fileDataJSON], fType);

                    const result = await connector.uploadFileToBucket(this, 'documentation', this.session.user.id + '/' + client_id_folder, file_name, blob, '3600', 'application/json');
                    if (result === null) {
                        // error has already been shown
                        newUploadedFiles.push(null);
                        continue;
                    }

                    const uploadedFile = {
                        id: result.id,
                        name: originalFileName,
                        storageName: file_name,
                        size: fileSize,
                        icon: this.getFileIcon(file.type),
                        iconColor: this.getIconColor(file.type),
                        mimetype: file.type,
                        uploading: false,
                        appointment_id: this.selected_appointment_id,
                        created_at: new Date().toISOString(),
                        updated_at: new Date().toISOString(),
                    };

                    if (this.selected_appointment_id === null) {
                        const placeholderIndex = this.uploadedFiles.findIndex(file => file.id === placeholderId);
                        if (placeholderIndex !== -1) {
                            this.$set(this.uploadedFiles, placeholderIndex, uploadedFile);
                        } else {
                            // this should not happen, probalby reload all files.
                            this.uploadedFiles.push(uploadedFile);
                        }
                    } else {
                        const placeholderIndex = this.uploadedAppointmentFiles.findIndex(file => file.id === placeholderId);
                        if (placeholderIndex !== -1) {
                            this.$set(this.uploadedAppointmentFiles, placeholderIndex, uploadedFile);
                        } else {
                            // this should not happen, probalby reload all files.
                            this.uploadedAppointmentFiles.push(uploadedFile);
                        }
                    }
                    newUploadedFiles.push(true);
                } catch (error) {
                    this.$emit('showError', {
                        message: 'Es ist ein Fehler während des hochladen der Datei passiert. Bitte versuche es erneut.',
                        timeout: 10000,
                        error: error
                    });
                    newUploadedFiles.push(null);
                }
            }

            return newUploadedFiles;
        },

        getFileIcon(type) {
            // Return an icon based on the file type
            switch (type) {
                case 'application/pdf':
                    return 'mdi-file-pdf-box';
                case 'image/jpeg':
                case 'image/png':
                case 'image/heic':
                    return 'mdi-file-image';
                // Add more cases for different file types
                default:
                    return 'mdi-file-document-outline';
            }
        },

        getIconColor(type) {
            // Return a color based on the file type
            switch (type) {
                case 'application/pdf':
                    return 'red';
                case 'image/jpeg':
                case 'image/png':
                case 'image/heic':
                    return 'orange';
                // Add more cases for different file types
                default:
                    return 'blue';
            }

        },

        async openFile(file) {
            file.uploading = true; // Show loading spinner
            await this.$refs.filePreview.openFile(file);
            file.uploading = false; // Hide loading spinner
        },
    },

    computed: {
        sortedAllUploadedFiles() {

            const nonAppointmentFiles = this.uploadedFiles.filter(file => !file.appointment_id)
                .sort((a, b) => dayjs(b.updated_at).diff(dayjs(a.updated_at)));

            const appointmentFiles = this.uploadedAppointmentFiles.filter(file => file.appointment_id)
                .sort((a, b) => {
                    const dateA = this.getDateFromAppointmentId(a.appointment_id, true);
                    const dateB = this.getDateFromAppointmentId(b.appointment_id, true);
                    if (!dateA || !dateB) {
                        // potential error during loading appointments, therefore just return 0 (considered equal in sorting)
                        return 0;
                    }
                    return dateB.diff(dateA);
                });

            return [...nonAppointmentFiles, ...appointmentFiles];
        },
    }
}
</script>

<style scoped>
.v-sheet.v-card {
  border-radius: 6px;
}

.upload-area {
    border: dashed 2px #1976d2 !important;
    border-radius: 20px;
    text-align: center;
}

.upload-area.drag-over {
    background-color: #f0f0f0;
}

/* Additional styles for the file info container */
.file-info-container {
    min-width: 0;
    /* This ensures that the container can shrink as needed */
}

/* Ensure text truncation is applied */
.text-truncate-file {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-card {
    transition: box-shadow .3s ease;
    cursor: pointer;
    /* position: relative; */
    /* Ensure the positioning context for the speed dial */
}

</style>