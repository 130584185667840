<template>
    <v-card class="fill-height" :color="$store.state.theme.background">
        <v-toolbar dark :color="$store.state.theme.primary">
            <v-btn icon dark @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Monatsabrechnung</v-toolbar-title>
            <v-spacer></v-spacer>
            <strong v-if="haveCustomersToInvoice" class="white--text">Rechnungen bearbeitet</strong>
            <v-progress-circular
                v-if="haveCustomersToInvoice"
                :size="50"
                :width="5"
                :value="progress"
                :rotate="-90"
                :color="'white'"
                class="ml-4"
            >
                <span :style="{ fontSize: fontSize }">{{ current_client_idx }} / {{ customers.length }}</span>
            </v-progress-circular>
        </v-toolbar>

        <v-card v-if="!finished_creating_invoices && haveCustomersToInvoice" class="mx-5 mt-5">
            <v-stepper v-model="step" elevation="0" class="my-0 py-0">
                <v-stepper-header class="elevation-1">
                    <div class="d-flex align-center px-5">
                        <v-icon large :color="$store.state.theme.primary" class="mr-3">mdi-account</v-icon>
                        <span class="text-h6">{{ nameOfCurrentClient }}</span>
                        <v-chip v-for="tag in tagsOfCurrentClient" :key="'chip-' + tag.id" class="ml-2" :small="$vuetify.breakpoint.smAndDown">{{ tag.merkmal }}</v-chip>
                    </div>
                    <div class="mx-2" style="border-left: 1px solid #d3d3d3; height: 50%; margin: auto 0"></div>
                    <v-stepper-step :color="$store.state.theme.primary" :complete="step > 1" step="1">
                        Rechnung erstellen
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :color="$store.state.theme.primary" :complete="step > 2" step="2">
                        Versenden
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card flat class="mb-0">
                            <v-card-title class="px-0 pt-0">
                                Rechnungsdetails
                            </v-card-title>
                            <v-card-text class="px-0">
                                <v-row>
                                    <v-col cols="12" class="d-flex">
                                        <div style="max-width: 300px;">
                                            <v-menu v-model="menu" :close-on-content-click="false" offset-y max-width="290px" min-width="auto">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field dense outlined v-model="formattedDate" label="Rechnungsdatum"
                                                        prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="mr-5"
                                                    />
                                                </template>
                                                <v-date-picker first-day-of-week="1" v-model="datum" no-title @input="menu = false" />
                                            </v-menu>
                                        </div>
                                        <div style="max-width: 300px;">
                                            <v-text-field dense outlined v-model="nummer" label="Rechnungsnummer" />
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-alert
                                    v-if="hasInvoiceYearMismatch"
                                    dense
                                    outlined
                                    color="orange"
                                    type="error"
                                    class="text-justify"
                                >
                                    <span class="font-weight-bold">Achtung:</span>
                                    Die Rechnungsnummer enthält das Jahr <strong>{{ invoiceYear }}</strong>, das sich vom Jahr des Rechnungsdatums
                                    <strong>{{ formatInvoiceDateYear(datum) }}</strong> unterscheidet. Bitte prüfe, ob dies korrekt ist.
                                    <p class="mt-3">
                                        Um die Rechnungsnummer auf das Jahr <strong>{{ formatInvoiceDateYear(datum) }}</strong> umzustellen, klicke auf <strong>Umstellen</strong>
                                        oder überschreibe die vorgeschlagene Rechnungsnummer.
                                        Sollte die Rechnungsnummer {{ nummer }} richtig sein, kannst du diese Warnung ignorieren.
                                    </p>
                                    <v-btn class="mb-3" :color="$store.state.theme.green" outlined @click="updateInvoiceNumberToCurrentYear">
                                        Umstellen
                                    </v-btn>
                                </v-alert>
                            </v-card-text>
                        </v-card>

                        <v-card flat class="mt-0 pt-0">
                            <v-card-title class="pb-2 px-0 pt-0">
                                Verrechenbare Termine
                                <v-chip :color="$store.state.theme.primary" dark small class="ml-2">
                                    <v-icon v-if="selected_appointments.length === 0" left small> mdi-cursor-default-click-outline </v-icon>
                                    {{ selected_appointments.length === 0 ? 'Wähle die zu verrechnenden Termine aus' : `${selected_appointments.length} ausgewählt` }}
                                </v-chip>
                            </v-card-title>
                            <v-data-table
                                v-model="selected_appointments"
                                :headers="headers_appointments"
                                hide-default-footer
                                :items="appointmentsOfCurrentClient"
                                item-key="id"
                                show-select
                                class="elevation-1 mt-1 mx-1 clickable-rows"
                                :items-per-page="-1"
                                @click:row="toggleSelection"
                            >
                                <template v-slot:item.datum="{ item }">
                                    <span>
                                        {{
                                            new Date(item.datum).toLocaleString([], {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            })
                                        }}
                                    </span>
                                    <v-chip v-if="isAfterNow(item.datum)" :color="$store.state.theme.orange"
                                        dark small label class="ml-2">
                                        <v-tooltip bottom :color="$store.state.theme.primary">
                                            <template v-slot:activator="{ on }">
                                                <span class="font-weight-black" v-on="on">Z</span>
                                            </template>
                                            Dieser Termin liegt in der Zukunft.
                                        </v-tooltip>
                                    </v-chip>
                                </template>
                                <template v-slot:item.preis="{ item }">
                                    <span v-if="item.dauer">{{ item.preis }}€ ({{ item.dauer }} Minuten)</span>
                                    <span v-else>{{ item.preis }} €</span>
                                </template>
                                <template v-slot:item.name="{ item }">
                                    {{ item.nachname }} {{ item.vorname }}
                                </template>
                                <template v-slot:body.append="{ headers }">
                                    <tr class="no-hover">
                                        <td class="py-3" :colspan="headers.length - 1">
                                            <div class="subtitle-1 font-weight-medium black--text">Rechnungsbetrag</div>
                                            <div class="subtitle-2 font-weight-regular">= Summe der ausgewählten Termine</div>
                                        </td>
                                        <td class="py-3">
                                            <div class="text-h5 black--text font-weight-bold">
                                                {{ amountOfSelectedAppointments }} €
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-card>

                        <v-row class="mt-4 pb-4">
                            <v-col class="d-flex justify-space-between">
                                <v-btn :color="$store.state.theme.green" @click="createInvoice" dark :loading="creating_invoice">
                                    <v-icon left>mdi-download</v-icon>
                                    Erstellen & Herunterladen
                                </v-btn>
                                <v-btn outlined @click="nextClient">
                                    Klient Überspringen
                                    <v-icon right>mdi-arrow-right</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <InvoiceSendEmail
                            ref="send_email_component"
                            :session="session"
                            abortName="Überspringen"
                            @close="nextClient"
                            @showError="$emit('showError', $event)"
                            @showInfo="$emit('showInfo', $event)"
                        />
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card>

        <div v-else-if="!haveCustomersToInvoice" class="d-flex flex-column fill-height justify-center align-center mx-5">
            <v-icon x-large :color="$store.state.theme.green"> mdi-check-circle-outline </v-icon>
            <span class="mt-5 text-h6">Aktuell gibt es keine Rechnungen zu erstellen.</span>
            <v-btn :color="$store.state.theme.green" @click="close" outlined class="mt-5">
                Schließen
            </v-btn>
        </div>

        <div v-else class="d-flex flex-column fill-height justify-center align-center mx-5">
            <v-icon x-large :color="$store.state.theme.green">mdi-check-circle-outline</v-icon>
            <span class="mt-5 text-h6">Monatsabrechnung abgeschlossen</span>
            <v-btn :color="$store.state.theme.green" @click="close" outlined class="mt-5">
                Schließen
            </v-btn>
        </div>
    </v-card>
</template>

<script>
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
import connector from '../helpers/supabase-connector.js';
import invoiceHelper from '../helpers/invoices.js';
import InvoiceSendEmail from './InvoiceSendEmail.vue';
import { saveAs } from "file-saver";

export default {
    props: ['session', 'customers', 'appointments', 'invoice_number', 'invoices', 'dialog_quick_rechnung', 'invoice_templates'],
    emits: ['close', 'showError', 'showInfo', 'updateInvoices', 'missingTags'],
    components: {
        InvoiceSendEmail
    },
    data() {
        return {

            menu: false,
            step: 1,
            datum: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10),

            creating_invoice: false,

            headers_appointments: [
                { text: 'Klient', value: 'name' },
                { text: 'Datum', value: 'datum' },
                { text: 'Dienstleistung', value: 'bezeichnung' },
                { text: 'Preis', value: 'preis' }
            ],

            default_client: {
                fk_klienten_id: null,
                name: null,
                email: null,
            },

            current_client: {
                fk_klienten_id: null,
                name: null,
                email: null,
            },

            selected_appointments: [],
            current_client_idx: 0,
            nummer: this.invoice_number,
            finished_creating_invoices: false,
            last_invoice_id: null,
            current_invoice: null,
        };
    },

    mounted() {},

    watch: {
        invoice_number() {
            this.nummer = this.invoice_number;
        },
        dialog_quick_rechnung() {
            if (this.dialog_quick_rechnung) {
                // dialog opened, reset all values

                this.step = 1;
                this.current_client_idx = 0;
                this.current_client = Object.assign({}, this.default_client);
                this.selected_appointments = [];
                this.finished_creating_invoices = false;
                this.current_invoice = null;
                this.last_invoice_id = null;
            }
        },
    },

    computed: {

        invoiceYear() {
            try {
                // If there's no invoice number yet, return null to avoid errors
                if (!this.nummer) return null;
                
                // 1) Detect the format (e.g. "{NR}/{JAHR}" or "{JAHR}-{NR}")
                const format = invoiceHelper.getInvoiceFormat(this.nummer);

                // 2) Parse the invoice number with the detected format
                const { year } = invoiceHelper.parseInvoiceNumber(this.nummer, format);

                // Return the parsed year (e.g. 2024)
                return year;
            } catch (error) {
                // If parsing fails (e.g., invalid format), return null
                return null;
            }
        },

        hasInvoiceYearMismatch() {
            // If we couldn’t parse a valid year, we do not show a warning
            if (!this.invoiceYear || !this.datum) return false;

            const invoiceDateYear = dayjs(this.datum).year();

            return this.invoiceYear !== invoiceDateYear;
        },

        digitsInIndex() {
            return this.current_client_idx.toString().length + this.customers.length.toString().length;
        },

        fontSize() {
            return this.digitsInIndex > 3 ? '0.7rem' : '0.9rem';
        },

        formattedDate() {
            return dayjs(this.datum).format('DD.MM.YYYY');
        },

        nameOfCurrentClient() {
            if (this.customers.length === 0 || this.current_client_idx >= this.customers.length) {
                return '';
            }
            return this.customers[this.current_client_idx].name;
        },

        tagsOfCurrentClient() {
            if (this.customers.length === 0 || this.current_client_idx >= this.customers.length) {
                return [];
            }
            return this.customers[this.current_client_idx].merkmale;
        },

        appointmentsOfCurrentClient() {
            if (this.customers.length === 0 || this.current_client_idx >= this.customers.length) {
                return [];
            }
            return this.appointments.filter(
                (appointment) => appointment.fk_klienten_id == this.customers[this.current_client_idx].fk_klienten_id
            );
        },

        amountOfSelectedAppointments() {
            let sum = 0;
            this.selected_appointments.forEach((appointment) => {
                sum += appointment.preis;
            });
            return sum;
        },

        progress() {
            if (this.customers.length === 0) return 0;
            return (this.current_client_idx / this.customers.length) * 100;
        },

        haveCustomersToInvoice() {
            return this.customers.length > 0;
        },
    },

    methods: {

        updateInvoiceNumberToCurrentYear() {
            const result = invoiceHelper.updateInvoiceNumberToCurrentYear(
                this.nummer, 
                this.datum
            );
            
            if (result.status === "success") {
                this.nummer = result.invoiceNumber;
            } else {
                this.$emit('showError', { 
                    message: result.message, 
                    error: result.error 
                });
            }
        },

        formatInvoiceDateYear(dateStr) {
            if (!dateStr) return null
            return dayjs(dateStr).format('YYYY')
        },

        toggleSelection(item) {
            const index = this.selected_appointments.findIndex(selected => selected.id === item.id);
            if (index === -1) {
                this.selected_appointments.push(item);
            } else {
                this.selected_appointments.splice(index, 1);
            }
        },

        isAfterNow(dateString) {
            const parsed_date = dayjs.tz(dateString, 'YYYY-MM-DDTHH:mm:ss', 'Europe/Vienna');
            const now = dayjs().tz('Europe/Vienna');
            return parsed_date.isAfter(now);
        },

        async createInvoice() {
            // check if invoice number is entered.
            if (this.nummer === '' || this.nummer === null || !this.nummer) {
                this.$emit('showError', { message: 'Eine Rechungsnummer muss vergeben werden.', timeout: 7000 });
                return;
            }

            let invoice = {
                uid: this.session.user.id,
                nummer: this.nummer,
                datum: this.datum,
                fk_klienten_id: this.customers[this.current_client_idx].fk_klienten_id,
                fk_institution_id: null
            };

            // if (!this.$store.state.client.eigenes_schema) {
            //     let invoice_regex = /^\d+[a-zA-Z]?([/])\d{4}$/;
            //     if (!invoice_regex.test(invoice.nummer)) {
            //         this.$emit('showError', {
            //             message: 'Die Rechungsnummer muss folgendes Format haben: NR/JJJJ (zb. 1/2022).',
            //         });
            //         return;
            //     }
            // }

            // check if invoice number is in correct format
            if (this.$store.state.client.rechnungsnummer_format) {
                const invoice_regex = invoiceHelper.generateInvoiceRegex(this.$store.state.client.rechnungsnummer_format);
                if (!invoice_regex.test(invoice.nummer)) {
                    this.$emit('showError', { 
                        message: "Die Rechungsnummer muss folgendes Format haben: " + this.$store.state.client.rechnungsnummer_format,
                        timeout: 7000
                    });
                    return;
                }
            } else if (this.$store.state.client.eigenes_schema) {
                // do nothing, as we do not have a client schema
            } else {
                const invoice_regex = invoiceHelper.generateInvoiceRegex('{NR}/{JAHR}');
                if (!invoice_regex.test(invoice.nummer)) {
                    this.$emit('showError', { 
                        message: "Die Rechungsnummer muss folgendes Format haben: NR/JJJJ (zb. 1/2022).", 
                        timeout: 7000
                    });
                    return;
                }
            }

            if (this.selected_appointments.length === 0) {
                this.$emit('showError', { message: 'Mindestens ein Termin muss für eine Rechnung ausgewählt werden.', timeout: 7000 });
                return;
            }

            this.creating_invoice = true;

            this.last_invoice_id = await connector.createInvoice(this, invoice, this.selected_appointments.map((appointment) => appointment.id));
            if (!this.last_invoice_id) {
                // error was alredy shown in createInvoice
                this.creating_invoice = false;
                // do not increase step
                return;
            }

            this.$emit('updateInvoices', async (invoices) => {
                // set invoice item for download
                this.current_invoice = invoices.find((invoice) => invoice.id === this.last_invoice_id);
                this.current_client = Object.assign({}, this.customers[this.current_client_idx]);

                // Get the invoice template
                let invoice_template = null;
                if (this.invoice_templates.length > 0 && (this.$store.state.client.standard_vorlage !== null || this.current_invoice.fk_standard_vorlage !== null)) {
                    if (this.current_invoice.fk_standard_vorlage !== null) {
                        invoice_template = this.invoice_templates.find((template) => template.id === this.current_invoice.fk_standard_vorlage);
                    } else {
                        invoice_template = this.invoice_templates.find((template) => template.id === this.$store.state.client.standard_vorlage);
                    }
                }

                // Initialize the send email component with the invoice data
                const result = await this.$refs.send_email_component.openSendEmail(
                    {
                        ...this.current_invoice,
                        email: this.current_client.email,
                        vorname: this.current_client.vorname,
                        nachname: this.current_client.nachname,
                        titel_vorgestellt: this.current_client.titel_vorgestellt,
                        titel_nachgestellt: this.current_client.titel_nachgestellt,
                        empfänger_vorname: this.current_client.empfänger_vorname,
                        empfänger_nachname: this.current_client.empfänger_nachname,
                        empfänger_titel_vorgestellt: this.current_client.empfänger_titel_vorgestellt,
                        empfänger_titel_nachgestellt: this.current_client.empfänger_titel_nachgestellt,
                        empfänger_email: this.current_invoice.empfänger_email,
                        rechnungs_empfänger: this.current_invoice.rechnungs_empfänger,
                        telefon: this.current_invoice.telefon,
                        svnr: this.current_invoice.svnr,
                        geburtsdatum: this.current_invoice.geburtsdatum,
                        nummer: this.current_invoice.nummer
                    },
                    invoice_template
                );

                if (result.status !== 'success') {
                    this.$emit('missingTags', result, this.current_invoice);
                    this.creating_invoice = false;
                    this.nextClient();
                    return;
                }

                if (result.status === 'success') {
                    await saveAs(result.blob, result.filename);
                }

                this.creating_invoice = false;
                this.step++;
            });
        },

        nextClient() {
            if (this.current_client_idx < this.customers.length - 1) {
                this.current_client_idx++;
                this.selected_appointments = [];
                this.step = 1;
                this.current_invoice = null;
                return;
            }
            // also +1 because of 0-index to show that all invoices are created
            this.current_client_idx++;
            this.finished_creating_invoices = true;
        },

        close() {
            this.step = 1;
            this.current_client_idx = 0;
            this.current_client = Object.assign({}, this.default_client);
            this.selected_appointments = [];
            this.finished_creating_invoices = false;
            this.current_invoice = null;
            this.last_invoice_id = null;
            this.$emit('close');
        },
    },
};
</script>

<style scoped>
.v-sheet.v-card {
  border-radius: 6px;
}
.v-data-table >>> .no-hover:hover {
  background-color: transparent !important;
  cursor: default;
}
.clickable-rows ::v-deep tbody tr {
  cursor: pointer;
}
</style>