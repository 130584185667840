<template>
  <v-container fluid>

    <v-dialog v-model="dialogEnterSafeKey" max-width="600px" persistent>
      <v-card>
        <v-toolbar :color="$store.state.theme.primary" dark>
          <v-toolbar-title>Sichere deinen ZEIPSY Account</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog_enter_safe_key = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="px-4">
          <v-container fluid>
            <v-row>
              <v-col>
                <div class="text-h6">Dein persönlicher Safe-Schlüssel</div>
                <p class="subtitle-1 text-justify mt-2">
                  Die Daten-Sicherheit deiner Klienten hat höchste Priorität. Alle
                  personenbezogenen Daten (z.B. Name, Adresse, E-Mail Adresse etc.) der Klienten
                  werden verschlüsselt abgespeichert. Lege einmalig einen Safe-Schlüssel fest.
                  <b>Wähle einen anderen Safe-Schlüssel als dein Passwort bei ZEIPSY.</b>
                </p>

                <v-text-field v-model="$store.state.data_key" label="Safe-Schlüssel"
                  :append-icon="show_key ? 'mdi-eye' : 'mdi-eye-off'" :type="show_key ? 'text' : 'password'"
                  @click:append="show_key = !show_key" :rules="[rules.required, rules.min]" outlined dense></v-text-field>

                <p class="subtitle-1 text-justify">
                  <span class="font-weight-bold">Achtung:</span>
                  Dein Safe-Schlüssel wird ausschließlich auf deinem PC gespeichert. Vergisst du
                  ihn, können die Daten deiner Klienten nicht
                  wiederhergestellt werden. Notiere deinen Safe-Schlüssel und verwahre ihn sicher.
                  <!-- Du kannst den Schlüssel nicht mehr ändern, nachdem du einen festgelegt hast. -->
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="justify-end px-4 pb-3">
          <v-btn :color="$store.state.theme.red" text @click="dialog_enter_safe_key = false" class="mr-3">
            Abbrechen
          </v-btn>
          <v-btn :disabled="!specifiedSafeKey" :loading="loading_key" :color="$store.state.theme.green" text
            @click="save_safe_key">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template-preview-dialog
      :dialog="dialog_invoice_preview"
      :pdfUrl="invoice_preview_url"
      title="Rechnungsvorschau"
      :current_template="current_invoice_template"
      @close="closeInvoicePreview"
      @download="handleInvoiceDownload"
      @delete="deleteTemplate"
    ></template-preview-dialog>

    <template-preview-dialog
      :dialog="dialog_time_confirmation_preview"
      :pdfUrl="timeConfirmationPreviewUrl"
      title="Zeitbestätigung Vorschau"
      :current_template="current_time_confirmation_template"
      @close="closeTimeConfirmationPreview"
      @download="handleTimeConfirmationDownload"
      @delete="deleteTimeConfirmationTemplate"
    ></template-preview-dialog>

    <v-row :no-gutters="$vuetify.breakpoint.xsOnly" :class="$vuetify.breakpoint.xsOnly ? 'pt-5' : ''">
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="d-flex flex-column wrap pb-5">
          <div class="text-h5">Dein Safe-Schlüssel</div>
          <span class="text-subtitle-1 text-justify">
            Dein persönlicher Safe-Schlüssel um die personenbezogenen Daten deiner Klienten zu verschlüsseln. 
            Dieser Schlüssel wird nur lokal auf deinem PC gespeichert und kann daher nicht zurückgesetzt oder wiederhergestellt werden! 
          </span>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 8">
        <v-card class="pa-5">
          <v-container ma-0 pa-0 class="d-flex justify-space-between">
            <v-text-field dense outlined class="pl-0"  v-model="$store.state.data_key" label="Safe-Schlüssel" :append-icon="show_key ? 'mdi-eye' : 'mdi-eye-off'" 
              :type="show_key ? 'text' : 'password'" @click:append="show_key = !show_key" :rules="[rules.required, rules.min]" @keydown="changedSafeKey"></v-text-field>
          </v-container>
          <v-btn :disabled="saved_safe_key" @click="save_key" :color="saved_safe_key ? null : $store.state.theme.green" :dark="!saved_safe_key" block> 
            Safe-Schlüssel Speichern
          </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-divider class="my-5"></v-divider>

    <v-row :no-gutters="$vuetify.breakpoint.xsOnly">
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="d-flex flex-column wrap pb-5">
          <div class="text-h5">Dein ZEIPSY Profil</div>
          <span class="text-subtitle-1 text-justify">
            Hier kannst du deine E-Mail Adresse und dein Passwort für die Anmeldung bei ZEIPSY ändern.
          </span>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 8">
        <v-card class="pa-5">
          <v-form v-model="valid_email">
            <v-container class="d-flex justify-space-between ma-0 pa-0">
              <v-text-field dense outlined class="pl-0" v-model="email" label="E-Mail" :rules="emailRules">
              </v-text-field>
            </v-container>
            <v-btn class="mb-5" :disabled="email === session.user.email || !valid_email" @click="changeEmail" :dark="email !== session.user.email && valid_email" :color="email === session.user.email ? null : $store.state.theme.green" block> 
              E-Mail Ändern
            </v-btn>
          </v-form>

          <v-container class="d-flex mt-5 pa-0  justify-space-between">
            <v-text-field ref="passwort-vergessen" dense outlined class="mt-3 pl-0" v-model="password_zeipsy" label="Neues Passwort" :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'" 
              :type="show_password ? 'text' : 'password'" @click:append="show_password = !show_password" :rules="passwordChangeRules.concat([rules.required, rules.min])" @keydown="changedPassword"></v-text-field>
          </v-container>
          <v-btn :disabled="!changed_password" @click="updatePassword" :dark="changed_password" :color="!changed_password ? null : $store.state.theme.green" block> 
            Passwort Speichern
          </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-divider class="my-5"></v-divider>

    <v-row :no-gutters="$vuetify.breakpoint.xsOnly" ref="praxis_informationen">
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="d-flex flex-column pb-5">
          <div class="text-h5">Praxis-Informationen</div>
          <span class="text-subtitle-1 text-justify">
            Diese Informationen werden auf deinen ausgestellten Rechnungen angezeigt.
          </span>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 8">
        <v-card class="pa-5">
          <v-form v-model="valid">
            <v-row class="my-0">
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-text-field dense outlined v-model="$store.state.client.name" label="Praxis-Name" @keydown="change" ref="praxis_name" :rules="nameRules" />
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-text-field dense outlined v-model="$store.state.client.bezeichnung" label="Berufsbezeichnung" @keydown="change" ref="bezeichnung" :rules="bezeichnungRules" />
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-text-field dense outlined v-model="$store.state.client.telefon" label="Telefonnummer" @keydown="change" ref="praxis_telefon" :rules="telefonRules" />
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-text-field dense outlined v-model="$store.state.client.adresse" label="Adresse" @keydown="change" ref="praxis_adresse" :rules="adresseRules" />
              </v-col>  
              <v-col cols="6" sm="6" class="my-0 py-0">
                <v-text-field dense outlined type="number" v-model="$store.state.client.plz" label="PLZ" @keydown="change" ref="praxis_plz" :rules="plzRules" />
              </v-col>  
              <v-col cols="6" sm="6" class="my-0 py-0">
                <v-text-field dense outlined v-model="$store.state.client.ort" label="Ort" @keydown="change" ref="praxis_ort" :rules="ortRules" />
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-text-field dense outlined v-model="$store.state.client.iban" label="IBAN" @keydown="change" ref="praxis_iban" :rules="ibanRules" />
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-text-field dense outlined v-model="$store.state.client.bic" label="BIC" @keydown="change" ref="praxis_bic" :rules="bicRules" />
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-text-field dense outlined v-model="$store.state.client.vpnr" label="Vertragspartner-Nummer (VPNR)" @keydown="change" ref="praxis_vpnr" />
              </v-col>
            </v-row>

            <v-btn :disabled="saved_customer_information" :color="saved_customer_information ? null : $store.state.theme.green" :dark="!saved_customer_information" @click="save_customer_data" block>
              Änderungen speichern
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <v-divider class="my-5"></v-divider>

    <v-row :no-gutters="$vuetify.breakpoint.xsOnly">
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="d-flex flex-column pb-5">
          <div class="text-h5">Eigene Rechnungsvorlagen</div>
          <span class="text-subtitle-1 text-justify">
            Hier kannst du deine eigenen Rechnungsvorlagen erstellen und verwalten. 
            Gib deinen Vorlagen passende Namen, damit du sie besser zuordnen kannst.
            Du kannst eine Vorlage als Standard festlegen, die dann automatisch für die Rechnungen verwendet wird.
            Du kannst allerdings jeden Klienten eine andere Vorlage zuweisen.
            <br/><span class="font-weight-black">Tipp:</span>
            Um deine eigene Rechnungsvorlage zu erstellen, lade am besten die ZEIPSY-Vorlage herunter, bearbeite sie nach deinen Wünschen und lade sie anschließend wieder hoch.
            Deine Unterschrift und Stempel kannst du als Bild in der Vorlage einfügen.
          </span>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 8">
          <v-card class="d-flex flex-column pa-5">
            <p class="text-h6">Rechnungsvorlagen</p>
            <v-row>
              <v-col v-for="(invoice_template, index) in invoice_templates" :key="index" :cols="$vuetify.breakpoint.xs ? 12 : 6">
                <v-hover v-slot="{ hover }">
                    <v-card style="height: 100%" :class="isDefaultTemplate(invoice_template) ? 'd-flex align-center px-3 py-4 file-card active' : 'd-flex align-center px-3 py-4 file-card'" :elevation="hover ? 2 : 0" outlined>
                        <div class="file-info-container d-flex flex-column justify-center flex-grow-1">
                            <div class="d-flex align-center text-overline text-truncate-file">
                              <v-icon left small>mdi-invoice-text-outline</v-icon>
                              <span class="mr-1">{{ invoice_template.displayName }}</span>
                              <span class="text-caption">{{ invoice_template.updated_at_formatted ? '(' + invoice_template.updated_at_formatted + ')' : '' }}</span>
                            </div>
                            <div>
                              <v-btn v-if="isDefaultTemplate(invoice_template)" :color="$store.state.theme.green" elevation="0" outlined rounded dark class="mr-3 mb-2" small @click="setAsDefaultTemplate(invoice_template)">
                                Als Standard festgelegt
                              </v-btn>
                              <v-btn v-else :color="$store.state.theme.green" elevation="0" rounded dark class="mr-3 mb-2" small @click="setAsDefaultTemplate(invoice_template)">
                                Als Standard verwenden
                              </v-btn>
                              <v-btn v-if="invoice_template.id" icon :color="$store.state.theme.red" class="mb-2" @click="deleteTemplate(invoice_template)">
                                <v-tooltip bottom open-delay="300">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on">
                                      mdi-delete
                                    </v-icon>
                                  </template>
                                  <span>Vorlage löschen</span>
                                </v-tooltip>
                              </v-btn>
                              <br/>
                              <v-btn class="mr-3 mb-2" rounded elevation="1" :loading="invoice_template.previewing" @click="previewInvoice(invoice_template)" small>
                                <v-icon left>mdi-eye</v-icon>
                                Vorschau
                              </v-btn>
                              <v-btn class="mb-2" rounded elevation="1" :loading="invoice_template.downloading" @click="downloadTemplate(invoice_template)" small>
                                <v-icon left>mdi-download</v-icon>
                                Herunterladen
                              </v-btn>
                            </div>
                        </div>
                    </v-card>
                </v-hover>
              </v-col>
              <v-col v-if="invoice_templates.length === 0" :cols="$vuetify.breakpoint.xs ? 12 : 6">
                <v-skeleton-loader type="card" height="140px" />
              </v-col>
              <v-col cols="12">
                <v-card class="pa-5 upload-area" outlined
                        v-on:dragover.prevent="dragOver = true" v-on:dragleave.prevent="dragOver = false"
                        v-on:drop.prevent="handleDrop">
                        <div v-if="!dragOver">
                            <v-icon left>mdi-file-upload-outline</v-icon>
                            Ziehe deine eigene Rechnungsvorlage hier her um sie hochzuladen.
                        </div>
                        <div v-if="!dragOver" class="py-3">
                            Oder wähle die Rechnungsvorlage aus um sie hochzuladen.
                        </div>
                        <div v-if="dragOver" class="py-5 text-h6">Lass einfach los.</div>
                        <v-btn v-if="!dragOver" :color="$store.state.theme.primary" dark @click="handleFileImport" :loading="isUploading">
                            <v-icon left>
                                mdi-plus
                            </v-icon>
                            Vorlage hochladen
                        </v-btn>
                    </v-card>
                    <input ref="uploader" class="d-none" type="file" @change="onFileChanged" />
                    <v-alert class="mt-5 text-center" icon="mdi-information" text type="info">
                      Eine detaillierte Anleitung zum Erstellen einer eigenen Vorlage findest du im <a href="https://zeipsy.com/anleitungen/eigene-rechnungsvorlagen-erstellen-mit-zeipsy/" target="_blank">Hilfe-Center</a>.
                    </v-alert>
              </v-col>
            </v-row>

            <p class="text-h6 mt-5 mb-2">Zahlungsziel</p>
            <p class="text-justify">
              Hier kannst du das Zahlungsziel für deine Rechnungen festlegen. 
              Das Zahlungsziel wird mit dem Platzhalter <v-chip small>{zahlungsziel}</v-chip> auf deinen Rechnungen angezeigt und gibt an, bis wann die Rechnung beglichen werden soll bzw. im Falle einer Barzahlung, dass der Betrag erhalten wurde.
            </p>
            <v-row class="mt-3">
              <v-col cols="12" class="my-0 py-0">
                <v-text-field dense outlined v-model="$store.state.client.zahlungsziel_bank" label="Zahlungsziel bei Überweisung" @keydown="changePaymentInformation" persistent-placeholder placeholder="Bitte um Überweisung innerhalb von 14 Tagen ab Erhalt der Rechnung." />
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-text-field dense outlined v-model="$store.state.client.zahlungsziel_bar" label="Zahlungsziel bei Barzahlung" @keydown="changePaymentInformation" persistent-placeholder placeholder="Betrag dankend erhalten." />
              </v-col>
            </v-row>

            <v-btn class="mt-2" :disabled="saved_payment_information" :color="saved_payment_information ? null : $store.state.theme.green" :dark="!saved_payment_information" @click="updatePaymentDateInformation">
              Änderungen speichern
            </v-btn>

            <p class="text-h6 mt-8 mb-2">Eigene Rechnungsnummerierung</p>
            <p class="text-justify">
              Hier kannst du das Format deiner Rechnungsnummer ändern. 
              Um die Standardnummerierung zu verwenden, lasse das Feld für das Format einfach leer. Der Platzhalter {NR} wird durch eine fortlaufende Nummer und {JAHR} durch das aktuelle Jahr ersetzt.
              Folgende Formate sind möglich:
              <ul class="text-overline">
                <li><b>{NR}/{JAHR}</b> <v-chip small>Standardformat</v-chip></li>
                <li><b>{JAHR}/{NR}</b></li>
                <li><b>{NR}-{JAHR}</b></li>
                <li><b>{JAHR}-{NR}</b></li>
              </ul>
              
            </p>
            <v-form v-model="valid_invoice_format">
              <v-row class="mt-3">
                <v-col cols="6" class="my-0 py-0">
                  <v-text-field dense outlined v-model="$store.state.client.rechnungsnummer_format" label="Format der Rechnungsnummer" @keydown="changeInvoiceFormat" persistent-placeholder placeholder="{NR}/{JAHR}" :rules="invoiceFormatRules" />
                </v-col>
                <v-col cols="6" class="my-0 py-0">
                  <v-text-field dense outlined v-model="invoiceNumberPreview" readonly label="Vorschau" persistent-placeholder placeholder="1/2024" />
                </v-col>
              </v-row>
            </v-form>

            <v-btn class="mt-2" :disabled="saved_invoice_format || !valid_invoice_format" :color="saved_invoice_format ? null : $store.state.theme.green" :dark="!saved_invoice_format && valid_invoice_format" @click="updateInvoiceFormat">
              Änderungen speichern
            </v-btn>


            <p class="text-h6 mt-8 mb-2">Dateiname der Rechnung</p>
            <p class="text-justify">
              Hier kannst du den Dateinamen deiner Rechnung anpassen. Standardmäßig beginnt der Dateiname mit dem Namen des Klienten, gefolgt von der Rechnungsnummer. 
              Du hast jedoch die Möglichkeit, die Reihenfolge zu ändern, sodass zuerst die Rechnungsnummer und dann der Name des Klienten erscheint. 
              Dies kann für die Buchhaltung übersichtlicher sein.<br/>
              Die folgenden Formate stehen zur Auswahl:
            </p>
            <v-btn-toggle v-model="$store.state.client.schema_invoice_filename" mandatory exclusive @change="changedSchemaInvoiceName" :dense="$vuetify.breakpoint.smAndDown">
              <v-btn :value="null" :loading="settingNameFirstInvoiceName" :disabled="settingNrFirstInvoiceName">
                {{ $vuetify.breakpoint.smAndDown ? 'Name_Rech-Nr' : 'Name_Rechnungsnummer' }}
                <v-tooltip bottom open-delay="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      right
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>Beispiel: Musterfrau_Nina_01_2024.pdf</span>
                </v-tooltip>
              </v-btn>    
              <v-btn value="{NR}_{NAME}" :loading="settingNrFirstInvoiceName" :disabled="settingNameFirstInvoiceName">
                {{ $vuetify.breakpoint.smAndDown ? 'Rech-Nr_Name' : 'Rechnungsnummer_Name' }}
                <v-tooltip bottom open-delay="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      right
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>Beispiel: 01_2024_Musterfrau_Nina.pdf</span>
                </v-tooltip>
              </v-btn>
            </v-btn-toggle>
          </v-card>
      </v-col>
    </v-row>

    <v-divider class="my-5"></v-divider>

    <v-row :no-gutters="$vuetify.breakpoint.xsOnly">
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="d-flex flex-column pb-5">
        <div class="text-h5">Verschlüsselter-Rechnungsversand</div>
        <span class="text-subtitle-1 text-justify">
          Wähle die Standardoption für den Versand von Rechnungen per E-Mail. 
          Falls die gewählte Methode nicht verfügbar ist (z.B. aufgrund einer fehlenden SV-Nummer), wird automatisch die nächst sichere Methode verwendet.
          <br/>
          <span class="font-weight-black">Hinweis:</span>
            Die Kosten für den SMS-Versand betragen 0,12€ pro SMS.
        </span>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 8">
        <v-card class="pa-5">
          <v-radio-group v-model="defaultInvoiceSendMethod" @change="savedDefaultInvoiceSendMethod = false" class="mt-0 pt-0">
            <span class="text-h6 mb-3">Verschlüsselt mit Authentifizierung</span>
            <v-radio :color="$store.state.theme.primary" label="Verschlüsselt (SMS-Code)" value="password_sms">
              <template #label>
                  <div>
                      Einmal-Passwort per SMS
                      <div class="text-caption text--secondary">
                        Rechnung ist <strong>60 Tage</strong> verfügbar
                      </div>
                  </div>
                  <v-chip color="success" small class="ml-5 text-overline">
                            Empfohlen
                        </v-chip>
              </template>
            </v-radio>
            <v-radio :color="$store.state.theme.primary" label="Versicherungsnummer des Klienten" value="password_svnr">
              <template #label>
                  <div>
                    Versicherungsnummer des Klienten
                      <div class="text-caption text--secondary">Rechnung ist <strong>5 Tage</strong> verfügbar</div>
                  </div>
              </template>
            </v-radio>
            <v-radio :color="$store.state.theme.primary" label="Geburtsdatum des Klienten" value="password_birthdate">
              <template #label>
                  <div>
                    Geburtsdatum des Klienten
                      <div class="text-caption text--secondary">Rechnung ist <strong>5 Tage</strong> verfügbar</div>
                  </div>
              </template>
            </v-radio>
            <span class="text-h6 mb-3 mt-5">Unverschlüsselt per E-Mail Anhang</span>
            <v-radio :color="$store.state.theme.primary" label="Als PDF der E-Mail anhängen" :value="null"></v-radio>
          </v-radio-group>
          <v-btn 
            :disabled="savedDefaultInvoiceSendMethod" 
            @click="saveDefaultInvoiceSendMethod" 
            :color="savedDefaultInvoiceSendMethod ? null : $store.state.theme.green"
            :dark="!savedDefaultInvoiceSendMethod"
            block>
            Standardoption Speichern
          </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-divider class="my-5"></v-divider>

    <v-row :no-gutters="$vuetify.breakpoint.xsOnly">
      <v-col
        :cols="$vuetify.breakpoint.xs ? 12 : 4"
        class="d-flex flex-column pb-5"
      >
        <div class="text-h5">Zeitbestätigungsvorlage</div>
        <span class="text-subtitle-1 text-justify">
          Hier kannst du deine eigene Vorlage für die Zeitbestätigungen hochladen.
          Wenn du keine Vorlage hochlädst, wird die Standardvorlage verwendet.
          <br/>
          <span class="font-weight-black">Tipp:</span>
            Um deine eigene Vorlage zu erstellen, lade am besten die ZEIPSY-Vorlage herunter, bearbeite sie nach deinen Wünschen und lade sie anschließend wieder hoch.
            Deine Unterschrift und Stempel kannst du als Bild in der Vorlage einfügen.
        </span>
        <span class="text-h6 mt-5">Verfügbare Platzhalter</span>
        <div>
          <v-chip class="my-1 mr-2">
            <v-tooltip bottom open-delay="400">
              <template v-slot:activator="{ on, attrs }">
                <v-icon left v-bind="attrs" v-on="on">mdi-information</v-icon>
              </template>
              Vorname des Klienten
            </v-tooltip>
            {vorname}
          </v-chip>

          <v-chip class="my-1 mr-2">
            <v-tooltip bottom open-delay="400">
              <template v-slot:activator="{ on, attrs }">
                <v-icon left v-bind="attrs" v-on="on">mdi-information</v-icon>
              </template>
              Nachname des Klienten
            </v-tooltip>
            {nachname}
          </v-chip>

          <v-chip class="my-1 mr-2">
            <v-tooltip bottom open-delay="400">
              <template v-slot:activator="{ on, attrs }">
                <v-icon left v-bind="attrs" v-on="on">mdi-information</v-icon>
              </template>
              Aktuelles Datum
            </v-tooltip>
            {datum}
          </v-chip>

          <v-chip class="my-1 mr-2">
            <v-tooltip bottom open-delay="400">
              <template v-slot:activator="{ on, attrs }">
                <v-icon left v-bind="attrs" v-on="on">mdi-information</v-icon>
              </template>
              Datum des Termins
            </v-tooltip>
            {termin_datum}
          </v-chip>

          <v-chip class="my-1 mr-2">
            <v-tooltip bottom open-delay="400">
              <template v-slot:activator="{ on, attrs }">
                <v-icon left v-bind="attrs" v-on="on">mdi-information</v-icon>
              </template>
              Startzeit des Termins
            </v-tooltip>
            {termin_uhrzeit_start}
          </v-chip>

          <v-chip class="my-1 mr-2">
            <v-tooltip bottom open-delay="400">
              <template v-slot:activator="{ on, attrs }">
                <v-icon left v-bind="attrs" v-on="on">mdi-information</v-icon>
              </template>
              Endzeit des Termins
            </v-tooltip>
            {termin_uhrzeit_ende}
          </v-chip>

          <v-chip class="my-1 mr-2">
            <v-tooltip bottom open-delay="400">
              <template v-slot:activator="{ on, attrs }">
                <v-icon left v-bind="attrs" v-on="on">mdi-information</v-icon>
              </template>
              Name der Praxis
            </v-tooltip>
            {praxis_name}
          </v-chip>
        </div>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 8">
        <v-card class="d-flex flex-column pa-5">
          <p class="text-h6">Zeitbestätigungsvorlagen</p>
          <v-row>
            <!-- Default Template Card -->
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
              <v-hover v-slot="{ hover }">
                <v-card style="height: 100%" class="d-flex align-center px-3 py-4 file-card" :elevation="hover ? 2 : 0" outlined>
                  <div class="file-info-container d-flex flex-column justify-center flex-grow-1">
                    <div class="d-flex align-center text-overline text-truncate-file">
                      <v-icon left small>mdi-file-document-outline</v-icon>
                      <span class="mr-1">Standardvorlage</span>
                    </div>
                    <div>
                      <v-btn class="mr-3 mb-2" rounded elevation="1" :loading="defaultTemplateLoading" @click="previewTimeConfirmation('default')" small>
                        <v-icon left>mdi-eye</v-icon>
                        Vorschau
                      </v-btn>
                      <v-btn class="mb-2" rounded elevation="1" @click="downloadTimeConfirmationTemplate('default')" small>
                        <v-icon left>mdi-download</v-icon>
                        Herunterladen
                      </v-btn>
                    </div>
                  </div>
                </v-card>
              </v-hover>
            </v-col>

            <!-- Custom Template Card -->
            <v-col v-if="custom_timeconfirmation_template" :cols="$vuetify.breakpoint.xs ? 12 : 6">
              <v-hover v-slot="{ hover }">
                <v-card style="height: 100%" class="d-flex align-center px-3 py-4 file-card active" :elevation="hover ? 2 : 0" outlined>
                  <div class="file-info-container d-flex flex-column justify-center flex-grow-1">
                    <div class="d-flex align-center text-overline text-truncate-file">
                      <v-icon left small>mdi-file-document-outline</v-icon>
                      <span class="mr-1">Eigene Vorlage</span>
                      <span class="text-caption">{{ custom_timeconfirmation_template.updated_at_formatted ? '(' + custom_timeconfirmation_template.updated_at_formatted + ')' : '' }}</span>
                    </div>
                    <div>
                      <v-btn class="mr-3 mb-2" rounded elevation="1" :loading="customTemplateLoading" @click="previewTimeConfirmation('custom')" small>
                        <v-icon left>mdi-eye</v-icon>
                        Vorschau
                      </v-btn>
                      <v-btn class="mr-3 mb-2" rounded elevation="1" @click="downloadTimeConfirmationTemplate('custom')" small>
                        <v-icon left>mdi-download</v-icon>
                        Herunterladen
                      </v-btn>
                      <v-btn icon :color="$store.state.theme.red" class="mb-2" @click="deleteTimeConfirmationTemplate">
                        <v-tooltip bottom open-delay="300">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">
                              mdi-delete
                            </v-icon>
                          </template>
                          <span>Vorlage löschen</span>
                        </v-tooltip>
                      </v-btn>
                    </div>
                  </div>
                </v-card>
              </v-hover>
            </v-col>

            <!-- Upload Area -->
            <v-col cols="12">
              <v-card class="pa-5 upload-area" outlined
                      v-on:dragover.prevent="dragOverTimeConfirmation = true" 
                      v-on:dragleave.prevent="dragOverTimeConfirmation = false"
                      v-on:drop.prevent="handleTimeConfirmationDrop">
                <div v-if="!dragOverTimeConfirmation">
                  <v-icon left>mdi-file-upload-outline</v-icon>
                  Ziehe deine eigene Zeitbestätigungsvorlage hier her um sie hochzuladen.
                </div>
                <div v-if="!dragOverTimeConfirmation" class="py-3">
                  Oder wähle die Vorlage aus um sie hochzuladen.
                </div>
                <div v-if="dragOverTimeConfirmation" class="py-5 text-h6">Lass einfach los.</div>
                <v-btn v-if="!dragOverTimeConfirmation" 
                      :color="$store.state.theme.primary" 
                      dark 
                      @click="handleTimeConfirmationImport" 
                      :loading="isTimeConfirmationUploading">
                  <v-icon left>mdi-plus</v-icon>
                  Vorlage hochladen
                </v-btn>
              </v-card>
              <input ref="timeConfirmationUploader" class="d-none" type="file" accept=".docx" @change="onTimeConfirmationFileChanged" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-divider class="my-5"></v-divider>

    <v-row :no-gutters="$vuetify.breakpoint.xsOnly">
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="d-flex flex-column pb-5">
          <div class="text-h5">E-Mail Versandvorlage</div>
          <span class="text-subtitle-1 text-justify">
            Hier kannst du den Standard-Text für den E-Mail Versand deiner Rechnungen verändern. 
            Dieser Text wird automatisch in die E-Mail Nachricht vorsausgefüllt, wenn du eine Rechnung versendest.
            Du kannst den Text natürlich vor dem Versenden nach deinen Wünschen anpassen.
            <br/><span class="font-weight-black">Tipp:</span>
            Verwende Platzhalter um den E-Mail Text zu personalisieren. Diese werden automatisch durch die Daten deiner Klienten ersetzt.
          </span>
          <span class="text-h6 mt-5">Verfügbare Platzhalter</span>
          <div class="mt-3">
            <v-chip class="my-1 mr-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon left v-bind="attrs" v-on="on">mdi-information</v-icon>
                </template>
                Vorgestellter Titel des Rechnungsempfängers
              </v-tooltip>
              {empfänger_titel_vorgestellt}
            </v-chip>
            <v-chip class="my-1 mr-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon left v-bind="attrs" v-on="on">mdi-information</v-icon>
                </template>
                Vorname des Rechnungsempfängers
              </v-tooltip>
              {empfänger_vorname}
            </v-chip>
            <v-chip class="my-1 mr-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon left v-bind="attrs" v-on="on">mdi-information</v-icon>
                </template>
                Nachname des Rechnungsempfängers
              </v-tooltip>
              {empfänger_nachname}
            </v-chip>
            <v-chip class="my-1 mr-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon left v-bind="attrs" v-on="on">mdi-information</v-icon>
                </template>
                Nachgestellter Titel des Rechnungsempfängers
              </v-tooltip>
              {empfänger_titel_nachgestellt}
            </v-chip>
            <v-chip class="my-1 mr-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon left v-bind="attrs" v-on="on">mdi-information</v-icon>
                </template>
                Name deiner Praxis
              </v-tooltip>
              {praxis_name}
            </v-chip>
          </div>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 8">
        <v-card class="pa-5">
          <v-container ma-0 pa-0 class="d-flex flex-column">
            <v-text-field dense outlined v-model="subject" label="Betreff" @keydown="saved_email_template = false" />
            <v-textarea dense outlined v-model="draft_message" label="E-Mail Versandvorlage" auto-grow @keydown="saved_email_template = false" />
            <span class="text-h6">E-Mail Vorschau</span>
            <span class="mt-2 font-weight-bold">Betreff: {{ subject }}</span>
            <div v-html="messageComputed" class="preview-textarea"></div>
          </v-container>
          <v-btn @click="saveEMailTemplate" class="mt-5" :disabled="saved_email_template" :color="saved_email_template ? null : $store.state.theme.green" :dark="!saved_email_template" block>
            Änderungen Speichern
          </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-divider class="my-5"></v-divider>

    <v-dialog v-model="dialog_map_csv_columns" persistent>
      <v-card>
        <v-toolbar dark :color="$store.state.theme.primary">
          <v-btn icon :dark="!importing_clients" @click="dialog_map_csv_columns = false" :disabled="importing_clients">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>CSV Klienten Import</v-toolbar-title>
        </v-toolbar>

        <v-stepper v-model="activeStep">
          <v-stepper-header>
            <v-stepper-step step="1" :complete="activeStep > 1">
              Spalten Zuordnung
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2" :complete="activeStep > 2">
              Vorschau
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1" :style="{backgroundColor: '#f5f5f5'}">
              <div >                
                <p class="text-subtitle-1 text-justify">
                  Weise die Spalten deiner CSV-Datei den entsprechenden Feldern der Klienten zu.
                </p>

                <v-card outlined>
                  <v-card-text class="pt-0">
                    <div>
                      <v-row class="d-flex align-center py-2">
                        <v-col cols="3" class="d-flex align-center">
                          <span class="text-overline"><strong>Klientenfeld</strong></span>
                        </v-col>
                        <v-col cols="3" class="d-flex align-center">
                          <span class="text-overline">CSV Spalte</span>
                        </v-col>
                        <v-col cols="6" class="d-flex align-center">
                          <span class="text-overline">Beispiel</span>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                    </div>
                    <div v-for="field in csvFields" :key="field.clientField">
                      <v-row class="d-flex align-center py-2">
                        <v-col cols="3" class="d-flex align-center">
                          <span class="text-overline">
                            {{ field.clientField }}
                          </span>
                          <v-icon right v-if="field.csvColumn !== null" color="green">
                            mdi-check
                          </v-icon>
                          <v-icon v-else right small>
                            mdi-help
                          </v-icon>
                        </v-col>
                        <v-col cols="3" class="d-flex align-center">
                          <v-select
                            :items="csvColumns"
                            v-model="field.csvColumn"
                            label="Spalte auswählen"
                            hide-details
                            dense outlined clearable clear-icon="mdi-close-circle"
                            item-text="text"
                            item-value="value"
                            @change="mapCSVColumns"
                          ></v-select>
                        </v-col>
                        <v-col cols="6" class="d-flex align-center">
                          <span>{{ field.csvExampleData }}</span>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                    </div>
                  </v-card-text>
                </v-card>
                <div class="d-flex justify-end mt-5 mb-2">
                  <v-btn :color="$store.state.theme.red" text @click="abortCSVImport">Abbrechen</v-btn>
                  
                  <v-btn v-if="['vorname', 'nachname'].every(val => csvFields.filter((field) => field.csvColumn !== null).map((field) => field.dbFieldName).includes(val))" dark class="ml-5" :color="$store.state.theme.green" @click="activeStep++">
                    Weiter
                  </v-btn>
                  <v-tooltip v-else top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="ml-5">
                        <v-btn :color="$store.state.theme.green" disabled @click="activeStep++">
                          Weiter
                        </v-btn>
                      </div>
                    </template>
                    <span>Mindestens Vorname und Nachname müssen zugewiesen werden.</span>
                  </v-tooltip>
                </div>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2" :style="{backgroundColor: '#f5f5f5'}" class="pb-5">
              <p class="text-subtitle-1 text-justify">
                Es wurden {{ convertedCSVData.length }} Klienten in deiner CSV-Datei gefunden.
                <span v-if="duplicateCount > 0" class="">
                  {{ duplicateCount }} Duplikat{{ duplicateCount === 1 ? '' : 'e' }} wurden ausgeschlossen.
                </span>
                Wähle die Klienten aus, die du importieren möchtest und klicke auf "Import starten".<br/>
                <strong>Tipp: </strong>Mit dem Auswahl-Kästchen in der Kopfzeile kannst du alle Klienten auf einmal auswählen.
              </p>
              <span class="mb-1 ml-1 text-overline">Vorschau</span>
              <v-data-table
                class="px-5 py-3"
                v-model="selected_csv_clients"
                :headers="csvFields.map(field => ({ text: field.clientField, value: field.dbFieldName }))"
                :items="convertedCSVData"
                :items-per-page="-1"
                item-key="id"
                :show-select="!importing_clients"
              /> 
              <div class="d-flex justify-end mt-5 mb-2">
                <v-btn :color="$store.state.theme.red" text @click="abortCSVImport" :disabled="importing_clients">Abbrechen</v-btn>
                <v-btn :color="$store.state.theme.primary" text class="ml-5" @click="activeStep--" :disabled="importing_clients">Zurück</v-btn>
                <v-btn :color="$store.state.theme.green" :dark="selected_csv_clients.length > 0" class="ml-5" @click="importSelectedCSVClients" :disabled="selected_csv_clients.length === 0" :loading="importing_clients">Ausgewählte Importieren</v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_therapsy_import" persistent>
      <v-card :color="$store.state.theme.background" class="pb-5">
        <v-toolbar dark :color="$store.state.theme.primary">
            <v-btn icon dark @click="dialog_therapsy_import = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>TheraPsy Klienten Import</v-toolbar-title>            
        </v-toolbar>

        <div v-if="!start_import" class="px-5 pt-5">
            <span class="headline">Klienten importieren</span>
            <p class="text-subtitle-1 text-justify">
              Es wurden {{ therapsy_clients.length }} Klienten in deiner ZIP-Datei gefunden. 
              Klicke auf "Import starten" um die Klienten zu importieren.
            </p>
            <span class="mb-1 ml-1 text-subtitle-1">Vorschau</span>
            <v-data-table
              :headers="headers"
              :items="therapsy_clients"
              :items-per-page="5"
              class="elevation-1"
            /> 

          <div class="d-flex justify-end mt-5">
            <v-btn :color="$store.state.theme.red" text @click="dialog_therapsy_import = false">Abbrechen</v-btn>
            <v-btn :color="$store.state.theme.green" dark class="ml-5" @click="startImport">Import starten</v-btn>
          </div>
        </div>

        <div v-if="start_import" class="mt-5 px-5">

          <div class="d-flex align-center">
              <v-progress-circular
                  :size="25"
                  :width="5"
                  :value="progress"
                  :rotate="-90"
                  :color="$store.state.theme.primary"
                  class="mr-4"
            />
            <span class="headline">Klient {{ current_client + 1 }} von {{ therapsy_clients.length }}</span>
          </div>
          
      
          <v-card class="mt-5 pa-5">
            <v-row v-if="duplicate_client">
              <v-col>
                <span class="text-h6 red--text">Achtung: Klient bereits vorhanden</span>
                <p class="text-subtitle-1">
                  Der Klient {{ editedItem.vorname }} {{ editedItem.nachname }} ist bereits in deiner Klientenliste vorhanden. 
                  Überspringe diesen Klienten, falls du ihn nicht importieren möchtest.
                </p>
              </v-col>
            </v-row>
            <v-form v-model="valid">
              <v-row class="mt-3">
                <v-col class="my-0 py-0">
                  <v-text-field dense outlined v-model="editedItem.vorname" label="Vorname" />
                </v-col>
                <v-col class="my-0 py-0">
                  <v-text-field dense outlined v-model="editedItem.nachname" label="Nachname" />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="my-0 py-0" :cols="$vuetify.breakpoint.xs ? 12 : 5">
                  <v-text-field dense outlined v-model="editedItem.adresse" label="Straße & Nr." />
                </v-col>
                <v-col class="my-0 py-0" :cols="$vuetify.breakpoint.xs ? 6 : 2">
                  <v-text-field dense outlined v-model="editedItem.plz" label="PLZ" />
                </v-col>
                <v-col class="my-0 py-0" :cols="$vuetify.breakpoint.xs ? 6 : 5">
                  <v-text-field dense outlined v-model="editedItem.ort" label="Ort" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="my-0 py-0">
                  <v-text-field dense outlined v-model="editedItem.email" label="E-Mail" />
                </v-col>
                <v-col cols="6" class="d-flex align-center my-0 py-0">
                  <v-text-field dense outlined v-model="editedItem.telefon" label="Telefon" />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="my-0 py-0">
                  <v-text-field dense outlined v-model="editedItem.geburtsdatum" label="Geburtsdatum (TT.MM.JJJJ)"
                    hint="TT.MM.JJJJ" prepend-inner-icon="mdi-calendar" :rules="[rules_birthday.datum]" />
                </v-col>
                <v-col class="my-0 py-0">
                  <v-text-field dense outlined v-model="editedItem.svnr" label="Sozialversicherungsnr." />
                </v-col>
                <v-col class="my-0 py-0">
                  <v-autocomplete v-model="editedItem.versicherungsträger" :items="insurer" item-text="displayName" item-value="id" 
                  label="Versicherungsträger" outlined dense />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="my-0 py-0">
                  <v-textarea dense outlined v-model="editedItem.kommentar" label="Kommentar" rows="2" auto-grow />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="my-0 py-0">
                  <v-textarea dense outlined v-model="editedItem.zusatztext" label="Diagnosen" rows="3"
                              auto-grow persistent-placeholder placeholder="z.B.: Diagnose: F43.2: Anpassungsstörungen"
                  />
                </v-col>
              </v-row>
            </v-form>
            <v-card-actions>
              <v-btn :color="$store.state.theme.primary" text @click="importAllClients">Alle Klienten Importieren</v-btn>
              <v-spacer></v-spacer>
              <v-btn :color="$store.state.theme.primary" text @click="nextClient">Überspringen</v-btn>
              <v-btn :color="$store.state.theme.green" :dark="valid" @click="save" :disabled="!valid" class="ml-5">Speichern & Weiter</v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-card>
    </v-dialog>

    <v-row :no-gutters="$vuetify.breakpoint.xsOnly">
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="d-flex flex-column pb-5">
        <div class="text-h5">Daten importieren</div>
          <span class="text-subtitle-1 text-justify">
            Hier kannst du die Stammdaten deiner Klienten importieren. 
            Aktuell wird der Import von TheraPsy sowie per CSV Datei unterstützt.
            Lade dazu entweder die ZIP-Datei aus dem TheraPsy-Export oder eine CSV Datei hoch. 
          </span>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 8">
        <v-card class="d-flex flex-column pa-5 justify-space-around">
          <v-file-input dense outlined v-model="file"
                        label="Wähle die ZIP-Datei vom TheraPsy-Export oder eine CSV Datei aus."
                        @change="handleFile"
                        accept=".zip,.csv" class="mt-5"
                        truncate-length="50"
          />
          <v-alert class="text-center" icon="mdi-information" text type="info">
            Eine Anleitung wie du die ZIP-Datei für den Therapsy-Import erstellst findest du im <a href="https://zeipsy.com/anleitungen/therapsy-daten-importieren/" target="_blank">Hilfe-Center</a>.
          </v-alert>
        </v-card>
      </v-col>
    </v-row>

    <v-divider class="my-5"></v-divider>

    <v-row :no-gutters="$vuetify.breakpoint.xsOnly">
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="d-flex flex-column pb-5">
        <div class="text-h5">Daten exportieren</div>
        <span class="text-subtitle-1 text-justify">
          Hier kannst du die Stammdaten deiner Klienten als CSV-Datei exportieren.
          Wähle die Felder aus, die du exportieren möchtest.
        </span>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 8">
        <v-card class="d-flex flex-column pa-5">
          <v-btn @click="showExportDialog" block>
            <v-icon left>mdi-file-export</v-icon>
            Klienten exportieren
          </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog_export_csv" persistent max-width="1000" :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card>
        <v-toolbar dark :color="$store.state.theme.primary">
          <v-btn icon @click="dialog_export_csv = false" :disabled="exporting">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Klienten CSV Export</v-toolbar-title>
        </v-toolbar>

        <v-stepper v-model="exportStep">
          <v-stepper-header>
            <v-stepper-step step="1" :complete="exportStep > 1">
              Felder auswählen
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2" :complete="exportStep > 2">
              Vorschau
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <p class="text-subtitle-1 text-justify">
                Wähle die Felder aus, die du exportieren möchtest.
              </p>

              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="selectAllExportFields"
                        label="Alle Felder auswählen"
                        @change="toggleAllExportFields"
                      ></v-checkbox>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col 
                      v-for="field in exportFields" 
                      :key="field.dbFieldName"
                      cols="12" sm="6" md="4" lg="3"
                    >
                      <v-checkbox
                        v-model="field.selected"
                        :label="field.clientField"
                        :disabled="field.required"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <div class="d-flex justify-end mt-5 mb-2">
                <v-btn text :color="$store.state.theme.primary" @click="dialog_export_csv = false">Abbrechen</v-btn>
                <v-btn
                  outlined
                  :color="$store.state.theme.green"
                  class="ml-5"
                  @click="previewExport"
                  :disabled="selectedExportFields.length === 0"
                >
                  Weiter
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2">
              <p class="text-subtitle-1 text-justify mb-3">
                Vorschau der zu exportierenden Daten. Es werden {{ exportPreviewData.length }} Klient(en) exportiert.
              </p>

              <v-data-table
                :headers="selectedExportFields.map(field => ({ 
                  text: field.clientField, 
                  value: field.dbFieldName
                  }))"
                :items="exportPreviewData"
                :items-per-page="5"
              >
                <template v-slot:item.zusatztext="{ value }">
                  {{ truncateText(value) }}
                </template>
                <template v-slot:item.kommentar="{ value }">
                  {{ truncateText(value) }}
                </template>
              </v-data-table>

              <div class="d-flex flex-wrap justify-end mt-5 mb-2 gap-2">
                <v-btn outlined :color="$store.state.theme.green" @click="copyToClipboard" class="mb-2">
                  <v-icon left>mdi-content-copy</v-icon>
                  In die Zwischenablage kopieren
                </v-btn>
                <v-spacer class="d-none d-sm-block" />
                <div class="d-flex gap-2">
                  <v-btn text :color="$store.state.theme.primary" @click="exportStep--">Zurück</v-btn>
                  <v-btn
                    class="ml-5"
                    :loading="exporting"
                    :color="$store.state.theme.green"
                    dark
                    @click="exportData"
                  >
                    Exportieren
                  </v-btn>
                </div>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    
    <v-divider class="my-5"></v-divider>

    <v-row :no-gutters="$vuetify.breakpoint.xsOnly">
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="d-flex flex-column pb-5">
          <div class="text-h5">Account verwalten</div>
          <span class="text-subtitle-1 text-justify">
            Hier kannst du deinen Account verwalten.
            Du kannst z.B. deinen Google-Account verknüpfen um deinen Google-Kalender mit ZEIPSY zu synchronisieren.
            Außerdem kannst du hier deine Daten löschen. 
            Wenn du die Daten löschst, werden alle Daten zu deinen Klienten, Terminen und Rechnungen unwiederbringlich gelöscht.
          </span>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xs ? 12 : 8">
        <v-card class="d-flex pa-5 justify-space-between">
          <v-row>
            <v-col class="d-flex align-center">
              <v-container v-if="linked_google_account" fluid class="mx-0 px-0">
                <span class="text-overline mr-3">Verknüpftes Konto:</span>
                <v-chip>
                  {{ linked_google_account_email }}
                </v-chip>
                <v-btn @click="deleteLinkedGoogleAccount" block class="mt-5">
                  Google Verknüpfung entfernen
                </v-btn>
              </v-container>
              <v-btn v-else @click="linkGoogleAccount" block>
                Google Konto Verknüpfen
              </v-btn>
            </v-col>  
            <v-col class="d-flex align-center">
              <v-dialog v-model="dialog" width="600" persistent>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :color="$store.state.theme.red" class="white--text px-10" block v-on="on" v-bind="attrs">Daten löschen</v-btn>
                </template>
                <v-card>
                  <v-card-title class="headline">Daten löschen</v-card-title>
                  <v-card-text>
                    <p class="text-subtitle-1 text-justify">
                      Bist du sicher, dass du deine Daten löschen möchtest? 
                      <b>Es werden alle Daten zu den Klienten, Terminen, Rechnungen sowie der Dokumentation unwiederbringlich gelöscht.</b>
                      Deine Rechnungsvorlagen werden ebenso gelöscht. Nur die Praxis-Einstellungen bleiben erhalten.
                    </p>
                  </v-card-text>
                  <v-card-actions class="pb-5 px-6">
                    <v-spacer></v-spacer>
                    <v-btn :color="$store.state.theme.primary" text @click="dialog=false" :disabled="deleting">Abbrechen</v-btn>
                    <v-btn class="ml-3" :color="$store.state.theme.red" outlined @click="delete_data" :loading="deleting">Daten Löschen</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
  import { saveAs } from 'file-saver'
  import connector from '../helpers/supabase-connector.js'
  import cipher from '@/helpers/cipher'
  import PizZip from 'pizzip'
  import Docxtemplater from 'docxtemplater'
  import InspectModule from 'docxtemplater/js/inspect-module'
  import ExcelJS from 'exceljs'
  import JSZip from 'jszip'
  import dayjs from 'dayjs'
  import { supabase } from '../supabase'
  import invoiceHelper from '../helpers/invoices.js'
  import timeconfirmations from '@/helpers/timeconfirmations';
  import TemplatePreviewDialog from '@/components/TemplatePreviewDialog.vue';

  export default {
    props: ['session'],
    components: { TemplatePreviewDialog },
    data() {
      return {

          defaultInvoiceSendMethod: this.$store.state.client.default_invoice_send_method,
          savedDefaultInvoiceSendMethod: true,

          dialog_export_csv: false,
          exportStep: 1,
          exporting: false,
          selectAllExportFields: false,
          exportFields: [
            { dbFieldName: 'vorname', clientField: 'Vorname', selected: true, required: false },
            { dbFieldName: 'nachname', clientField: 'Nachname', selected: true, required: false },
            { dbFieldName: 'adresse', clientField: 'Straße & Nr.', selected: false, required: false },
            { dbFieldName: 'plz', clientField: 'PLZ', selected: false, required: false },
            { dbFieldName: 'ort', clientField: 'Ort', selected: false, required: false },
            { dbFieldName: 'email', clientField: 'E-Mail', selected: false, required: false },
            { dbFieldName: 'telefon', clientField: 'Telefon', selected: false, required: false },
            { dbFieldName: 'geburtsdatum', clientField: 'Geburtsdatum', selected: false, required: false },
            { dbFieldName: 'svnr', clientField: 'Sozialversicherungsnr.', selected: false, required: false },
            { dbFieldName: 'kommentar', clientField: 'Kommentar', selected: false, required: false },
            { dbFieldName: 'zusatztext', clientField: 'Diagnosen', selected: false, required: false },
          ],
          exportPreviewData: [],

          custom_timeconfirmation_template: null,
          defaultTemplateLoading: false,
          customTemplateLoading: false,
          dragOverTimeConfirmation: false,
          isTimeConfirmationUploading: false,
          timeConfirmationFile: null,
          updating_schema_invoice_filename: false,
          current_invoice_template: null,
          
          dialog_time_confirmation_preview: false,
          timeConfirmationPreviewUrl: null,
          current_time_confirmation_template: null,

          email: null,
          valid_email: true,
          dialog_invoice_preview: false,
          invoice_preview_url: null,

          dialog_map_csv_columns: false,
          csvColumns: [], // This should be populated with the actual CSV column names
          csvFields: [
            { dbFieldName: 'vorname', clientField: 'Vorname', csvColumn: null, csvExampleData: null },
            { dbFieldName: 'nachname', clientField: 'Nachname', csvColumn: null, csvExampleData: null },
            { dbFieldName: 'adresse', clientField: 'Straße & Nr.', csvColumn: null, csvExampleData: null },
            { dbFieldName: 'plz', clientField: 'PLZ', csvColumn: null, csvExampleData: null },
            { dbFieldName: 'ort', clientField: 'Ort', csvColumn: null, csvExampleData: null },
            { dbFieldName: 'email', clientField: 'E-Mail', csvColumn: null, csvExampleData: null },
            { dbFieldName: 'telefon', clientField: 'Telefon', csvColumn: null, csvExampleData: null },
            { dbFieldName: 'geburtsdatum', clientField: 'Geburtsdatum', csvColumn: null, csvExampleData: null },
            { dbFieldName: 'svnr', clientField: 'Sozialversicherungsnr.', csvColumn: null, csvExampleData: null },
            { dbFieldName: 'kommentar', clientField: 'Kommentar', csvColumn: null, csvExampleData: null }
          ],
          csvData: [],
          activeStep: 1,
          selected_csv_clients: [],
          importing_clients: false,

          subject: 'Rechnung',
          draft_message: 'Hallo {empfänger_vorname},\n\nanbei die aktuelle Rechnung.\n\nViele Grüße,\n{praxis_name}',
          saved_email_template: true,

          loading_key: false,
          show_key: true,
          rules: {
            required: (v) => !!v || 'Pflichtfeld.',
            min: (v) => (!!v && v.length >= 6) || 'Mindestens 6 Zeichen.',
          },

          dialog_enter_safe_key : false,
          file: null,
          therapsy_clients: [],
          current_client: 0,
          dialog_therapsy_import: false,
          start_import: false,
          duplicate_client: false,
          existing_clients: [],

          valid: true,
          dragOver: false,

          headers: [
            { text: 'Vorname', value: 'vorname' },
            { text: 'Nachname', value: 'nachname' },
            { text: 'E-Mail', value: 'email' },
            { text: 'Adresse', value: 'adresse' },
            { text: 'PLZ', value: 'plz' },
            { text: 'Ort', value: 'ort' },
            { text: 'SVNR', value: 'svnr' },
            { text: 'Diagnosen', value: 'zusatztext' }, 
            { text: 'Kommentar', value: 'kommentar', width: '300px' },

          ],

          valid: true,
          valid_invoice_format: true,
          
          rules_birthday: {
            datum: value => {
              // First, check if the value is null or matches the pattern
              if (value === null || value === undefined) return true;
              // Define the pattern
              const pattern = /(^$|^\s*(3[01]|[12][0-9]|0[1-9])\.(1[012]|0[1-9])\.((?:19|20)\d{2})\s*$)/;
              // Test the value against the pattern
              return pattern.test(value) || 'Ungültiges Datum.';
            },
          },

          emailRules: [
              v => !!v || 'E-Mail Adresse wird benötigt', // checks if the field is not empty
              v => /.+@.+\.[A-Za-z]{2,}/.test(v) || 'Gib eine gültige E-Mail Adresse an' // checks if the email is valid
          ],

          defaultItem: {
            uid: this.session.user.id,
            vorname: null,
            nachname: null,
            adresse: null,
            plz: null,
            ort: null,
            email: null,
            telefon: null,
            geburtsdatum: null,
            svnr: null,
            versicherungsträger: null,
            kommentar: null,
            zusatztext: null,
          },

          editedItem: {
            uid: this.session.user.id,
            vorname: null,
            nachname: null,
            adresse: null,
            plz: null,
            ort: null,
            email: null,
            telefon: null,
            geburtsdatum: null,
            svnr: null,
            versicherungsträger: null,
            kommentar: null,
            zusatztext: null,
          },

          // if update, also change in Klienten.vue
          // and also in the DB (versicherungen)
          insurer: [
            { "id": 1, "name": "ÖGK", "state": "Burgenland", "address": "Siegfried Marcus-Straße 5", "place": "7000 Eisenstadt", "displayName": "ÖGK (Burgenland)" },
            { "id": 2, "name": "ÖGK", "state": "Kärnten", "address": "Kempfstraße 8", "place": "9021 Klagenfurt am Wörthersee", "displayName": "ÖGK (Kärnten)" },
            { "id": 3, "name": "ÖGK", "state": "Niederösterreich", "address": "Kremser Landstraße 3", "place": "3100 St. Pölten", "displayName": "ÖGK (Niederösterreich)" },
            { "id": 4, "name": "ÖGK", "state": "Oberösterreich", "address": "Gruberstraße 77", "place": "4021 Linz", "displayName": "ÖGK (Oberösterreich)" },
            { "id": 5, "name": "ÖGK", "state": "Salzburg", "address": "Engelbert-Weiß-Weg 10", "place": "5020 Salzburg", "displayName": "ÖGK (Salzburg)" }, 
            { "id": 6, "name": "ÖGK", "state": "Steiermark", "address": "Josef-Pongratz-Platz 1", "place": "8010 Graz", "displayName": "ÖGK (Steiermark)" },
            { "id": 7, "name": "ÖGK", "state": "Tirol", "address": "Klara-Pölt-Weg 2", "place": "6020 Innsbruck", "displayName": "ÖGK (Tirol)" },
            { "id": 8, "name": "ÖGK", "state": "Vorarlberg", "address": "Jahngasse 4", "place": "6850 Dornbirn", "displayName": "ÖGK (Vorarlberg)" },
            { "id": 9, "name": "ÖGK", "state": "Wien", "address": "Wienerbergstraße 15-19", "place": "1100 Wien", "displayName": "ÖGK (Wien)" },
            
            { "id": 10, "name": "BVAEB", "state": "Kärnten", "address": "Siebenhügelstraße 1", "place": "9020 Klagenfurt am Wörthersee", "displayName": "BVAEB (Kärnten)" },
            { "id": 11, "name": "BVAEB", "state": "Oberösterreich", "address": "Hessenplatz 14", "place": "4020 Linz", "displayName": "BVAEB (Oberösterreich)" },
            { "id": 12, "name": "BVAEB", "state": "Salzburg", "address": "Faberstraße 2A", "place": "5020 Salzburg", "displayName": "BVAEB (Salzburg)" },
            { "id": 13, "name": "BVAEB", "state": "Steiermark", "address": "Grieskai 106", "place": "8020 Graz", "displayName": "BVAEB (Steiermark)" },
            { "id": 14, "name": "BVAEB", "state": "Tirol", "address": "Meinhardstraße 1", "place": "6010 Innsbruck", "displayName": "BVAEB (Tirol)" },
            { "id": 15, "name": "BVAEB", "state": "Vorarlberg", "address": "Montfortstraße 11", "place": "6900 Bregenz", "displayName": "BVAEB (Vorarlberg)" },
            { "id": 16, "name": "BVAEB", "state": "Wien, NÖ & Burgenland", "address": "Josefstädter Straße 80", "place": "1080 Wien", "displayName": "BVAEB (Wien, NÖ & Burgenland)" },
            
            { "id": 17, "name": "SVS", "state": "Burgenland", "address": "Siegfried Marcus-Straße 5", "place": "7000 Eisenstadt", "displayName": "SVS (Burgenland)" },
            { "id": 18, "name": "SVS", "state": "Kärnten", "address": "Bahnhofstraße 67", "place": "9020 Klagenfurt", "displayName": "SVS (Kärnten)" },
            { "id": 19, "name": "SVS", "state": "Niederösterreich", "address": "Neugebäudeplatz 1", "place": "3100 Sankt Pölten", "displayName": "SVS (Niederösterreich)" },
            { "id": 20, "name": "SVS", "state": "Oberösterreich", "address": "Hanuschstraße 34", "place": "4020 Linz", "displayName": "SVS (Oberösterreich)" },
            { "id": 21, "name": "SVS", "state": "Salzburg", "address": "Auerspergstraße 24", "place": "5020 Salzburg", "displayName": "SVS (Salzburg)" },
            { "id": 22, "name": "SVS", "state": "Steiermark", "address": "Körblergasse 115", "place": "8010 Graz", "displayName": "SVS (Steiermark)" },
            { "id": 23, "name": "SVS", "state": "Tirol", "address": "Klara-Pölt-Weg 1", "place": "6020 Innsbruck", "displayName": "SVS (Tirol)" },
            { "id": 24, "name": "SVS", "state": "Vorarlberg", "address": "Schloßgraben 14", "place": "6800 Feldkirch", "displayName": "SVS (Vorarlberg)" },
            { "id": 25, "name": "SVS", "state": "Wien", "address": "Wiedner Hauptstraße 84-86", "place": "1051 Wien", "displayName": "SVS (Wien)" },
            
            { "id": 26, "name": "LKUF", "state": "Oberösterreich", "address": "Leonfeldner Straße 11", "place": "4040 Linz", "displayName": "LKUF (Oberösterreich)" },
            { "id": 27, "name": "KFL", "state": "Oberösterreich", "address": "Böhmerwaldstraße 16", "place": "4020 Linz", "displayName": "KFL (Oberösterreich)" },
            { "id": 28, "name": "KFG", "state": "Oberösterreich", "address": "Friedrichstraße 11", "place": "4041 Linz", "displayName": "KFG (Oberösterreich)" },
            { "id": 29, "name": "KFA", "state": "Steiermark", "address": "Hauptplatz 1", "place": "8010 Graz", "displayName": "KFA (Graz)" },
            { "id": 30, "name": "KFA", "state": "Wien", "address": "Schlesingerplatz 5", "place": "1080 Wien", "displayName": "KFA (Wien)" },
            { "id": 31, "name": "KFA", "state": "Niederösterreich", "address": "Hauptplatz 1", "place": "2500 Baden", "displayName": "KFA (Baden)" },
            { "id": 32, "name": "KFA", "state": "Oberösterreich", "address": "Stadtplatz 27", "place": "4400 Steyr", "displayName": "KFA (Steyr)" },
            { "id": 33, "name": "KFA", "state": "Kärnten", "address": "Rathausplatz 1", "place": "9500 Villach", "displayName": "KFA (Villach)" },
            { "id": 34, "name": "KFA", "state": "Salzburg", "address": "Mirabellplatz 4", "place": "5024 Salzburg", "displayName": "KFA (Salzburg)" },
            { "id": 35, "name": "KFA", "state": "Salzburg", "address": "Schöndorferplatz 14", "place": "5400 Hallein", "displayName": "KFA (Hallein)" },
            { "id": 36, "name": "KUF", "state": "Tirol", "address": "Leopoldstraße 3", "place": "6020 Innsbruck", "displayName": "KUF (Tirol - Landesbedienstete)" },
            { "id": 37, "name": "KUF", "state": "Tirol", "address": "Adamgasse 7a", "place": "6020 Innsbruck", "displayName": "KUF (Tirol – Gemeindebeamte)" },
            { "id": 38, "name": "KFW", "state": "Oberösterreich", "address": "Stadtplatz 1", "place": "4600 Wels", "displayName": "KFA (Wels)" },
            { "id": 39, "name": "MKF", "state": "Oberösterreich", "address": "Hauptplatz 1", "place": "4041 Linz", "displayName": "MKF (Linz)" },
          ],


          dialog:false,
          loadingPreview:false,
          deleting:false,
          saved_customer_information: true,
          saved_payment_information: true,
          saved_invoice_format: true,

          saved_default_values: true,
          saved_safe_key: true,
          changed_password: false,
          updated_password: false,
          password_zeipsy: null,

          linked_google_account: false,
          linked_google_account_email: null,

          // for file upload invoice template
          isUploading: false,
          
          show_key:false,
          show_password:false,
          rules: {
            required: v => !!v || 'Pflichtfeld.',
            min: v => (!!v && v.length >= 6) || 'Mindestens 6 Zeichen.',
          },

          invoiceFormatRules: [
            v => v === null || v === '' || invoiceHelper.isValidFormat(v) || 'Ungültiges Format.',
          ],
          
          invoice_templates: [],
      }
    },

    mounted () {
      this.initialize();
    },

    computed: {

      selectedExportFields() {
        return this.exportFields.filter(field => field.selected);
      },

      // Returns the number of CSV rows that have valid first and last names and
      // that already exist in your `existing_clients`
      duplicateCount() {
        return this.csvData.filter(row => {
          let client = {};
          this.csvFields.forEach(field => {
            client[field.dbFieldName] = row[field.csvColumn];
          });
          // Only count rows that have both a first and last name
          if (!(client.vorname && client.nachname)) {
            return false;
          }
          // If the client already exists, then count it as duplicate
          return this.existing_clients.some(
            existing => existing.vorname === client.vorname && existing.nachname === client.nachname
          );
        }).length;
      },

      convertedCSVData() {
        return this.csvData.map((row, index) => {
          let client = {};
          this.csvFields.forEach((field) => {
            client[field.dbFieldName] = row[field.csvColumn];
          });
          client.id = index;
          return client;
        }).filter((client) => client.vorname && client.nachname && 
            !(this.existing_clients.some(existing => {
              return existing.vorname === client.vorname && existing.nachname === client.nachname;
            })) 
          );
      },

      invoiceNumberPreview() {
        return this.$store.state.client.rechnungsnummer_format ? this.$store.state.client.rechnungsnummer_format.replace('{NR}', '1').replace('{JAHR}', '2024') : '1/2024';
      },

      requiredFields() {
        if (this.$route.query.missing) {
          return this.$route.query.missing.split(',');
        } else {
          return [];
        }
      },

      adresseRules() {
        return this.requiredFields.includes('praxis_adresse') ? [v => !!v || 'Wird benötigt'] : [];
      },
      plzRules() {
        return this.requiredFields.includes('praxis_plz') ? [v => !!v || 'Wird benötigt'] : [];
      },
      ortRules() {
        return this.requiredFields.includes('praxis_ort') ? [v => !!v || 'Wird benötigt'] : [];
      },
      telefonRules() {
        return this.requiredFields.includes('praxis_telefon') ? [v => !!v || 'Wird benötigt'] : [];
      },
      nameRules() {
        return this.requiredFields.includes('praxis_name') ? [v => !!v || 'Wird benötigt'] : [];
      },
      bezeichnungRules() {
        return this.requiredFields.includes('bezeichnung') ? [v => !!v || 'Wird benötigt'] : [];
      },
      ibanRules() {
        return this.requiredFields.includes('praxis_iban') ? [v => !!v || 'Wird benötigt'] : [];
      },
      bicRules() {
        return this.requiredFields.includes('praxis_bic') ? [v => !!v || 'Wird benötigt'] : [];
      },
      passwordChangeRules() {
        return this.requiredFields.includes('passwort-vergessen') ? [v => !!v || 'Bitte lege hier ein neues Passwort fest.'] : [];
      },

      progress() {
            if (this.therapsy_clients.length === 0) return 0;
            return (this.current_client / this.therapsy_clients.length) * 100;
        },

      dialogEnterSafeKey() {
        return this.$store.state.aes_key === null && this.dialog_enter_safe_key
      },

      specifiedSafeKey() {
        return this.$store.state.data_key.length >= 6;
      },

      messageComputed() {
        // Replace the placeholders with span elements containing dummy data
        let preview = this.draft_message;
        let dummyData = {
          vorname: 'Max',
          nachname: 'Mustermann',
          empfänger_vorname: 'Max',
          empfänger_nachname: 'Mustermann',
          titel_vorgestellt: 'Dr. ',
          titel_nachgestellt: ', MSc.',
          empfänger_titel_vorgestellt: 'Dr. ',
          empfänger_titel_nachgestellt: ', MSc.',
          praxis_name: this.$store.state.client.name ? this.$store.state.client.name : '',
        };
        Object.keys(dummyData).forEach((key) => {
          const value = dummyData[key];
          const placeholder = new RegExp(`{${key}}`, 'gi');
          preview = preview.replace(placeholder, `<span class='px-2 green lighten-3 rounded-lg'>${value}</span>`);
        });
        return preview.replace(/[ \t]+(\r\n|\n|\r)/g, '$1');
      },

      settingNrFirstInvoiceName() {
        if (this.updating_schema_invoice_filename && this.$store.state.client.schema_invoice_filename === '{NR}_{NAME}') {
          return true;
        } 
        return false;
      },

      settingNameFirstInvoiceName() {
        if (this.updating_schema_invoice_filename && this.$store.state.client.schema_invoice_filename === null) {
          return true;
        } 
        return false;
      }
      
    },

    methods: {

      async saveDefaultInvoiceSendMethod() {
        
        const payload = { default_invoice_send_method: this.defaultInvoiceSendMethod };
        
        // Use your connector to update the kunden table
        const updated = await connector.update(this, "kunden", payload, this.session.user.id);
        if (updated !== null) {
          // Update the Vuex store with the new value
          this.$store.commit('setDefaultInvoiceSendMethod', this.defaultInvoiceSendMethod);
          this.savedDefaultInvoiceSendMethod = true;
          this.$emit('showInfo', { message: "Standardoption für den Rechnungsversand gespeichert.", timeout: 5000 });
        } else {
          // the error has already been shown
          return;
        }
      },

      async copyToClipboard() {
        try {
          // Create CSV content from the preview data
          const headers = this.selectedExportFields.map(field => field.clientField).join(';');
          const rows = this.exportPreviewData.map(client => {
            return this.selectedExportFields.map(field => {
              let value = client[field.dbFieldName] || '';
              // Replace newlines and ensure proper escaping for CSV
              if (typeof value === 'string') {
                value = value.replace(/\n/g, '\\n');
                // Wrap in quotes if contains semicolon or newline
                if (value.includes(';') || value.includes('\n')) {
                  value = `"${value}"`;
                }
              }
              return value;
            }).join(';');
          }).join('\n');

          const csvContent =  this.selectedExportFields.length === 1 ? `${rows}` : `${headers}\n${rows}`;

          // Copy to clipboard
          await navigator.clipboard.writeText(csvContent);

          this.$emit('showInfo', {
            message: 'Daten wurden in die Zwischenablage kopiert.',
            timeout: 5000
          });
        } catch (error) {
          this.$emit('showError', {
            message: 'Fehler beim Kopieren in die Zwischenablage.',
            timeout: 1000,
            error: error.message || error
          });
        }
      },

      truncateText(text, length = 30) {
        if (!text) return text;
        return text.length > length ? text.substring(0, length) + '...' : text;
      },

      showExportDialog() {
        this.exportStep = 1;
        this.dialog_export_csv = true;
        this.exportPreviewData = [];
        this.exporting = false;
      },

      toggleAllExportFields(value) {
        this.exportFields.forEach(field => {
          if (!field.required) {
            field.selected = value;
          }
        });
      },

      async previewExport() {
        try {
          let customers = await connector.getDataOnly(this, 'vwklienten', 'id', true);
          
          if (customers === -1) {
            // error has already been shown
            return;
          }

          if (this.$store.state.aes_key === null) {
            this.$emit('showError', {
              message: 'Dein Safe-Schlüssel wird benötigt, um die Klienten zu exportieren.',
              timeout: 7000
            });
            return;
          }

          const decryptedClients = await cipher.decryptArray(this, customers);
          this.exportPreviewData = decryptedClients;
          this.exportStep++;
        } catch (error) {
          this.$emit('showError', {
            message: 'Fehler beim Laden der Vorschau. Bitte versuche es erneut.',
            timeout: 7000,
            error: error.message || error,
          });
        }
      },

      async exportData() {
        this.exporting = true;
        try {
          // Create a new workbook and worksheet
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet('Klienten');

          // Add headers
          worksheet.columns = this.selectedExportFields.map(field => ({
            header: field.clientField,
            key: field.dbFieldName,
            width: 15 // default width, adjust as needed
          }));

          // Clean data by replacing newlines
          const cleanedData = this.exportPreviewData.map(client => {
            let cleanedClient = {};
            this.selectedExportFields.forEach(field => {
              let value = client[field.dbFieldName];
              if (value && typeof value === 'string') {
                // Replace newlines with a space + pipe + space
                value = value.replace(/\n/g, '\\n');
              }
              cleanedClient[field.dbFieldName] = value;
            });
            return cleanedClient;
          });

          // Add data
          worksheet.addRows(cleanedData);

          // Generate CSV with proper encoding
          const buffer = await workbook.csv.writeBuffer({
            formatterOptions: {
              delimiter: ';',
              quote: true, // Always quote fields
              encoding: 'utf-8',
              quoteColumns: true
            }
          });

          // Add BOM for Excel
          const BOM = '\uFEFF';
          const blob = new Blob([BOM, buffer], { 
            type: 'text/csv;charset=utf-8;' 
          });
          
          const filename = `ZEIPSY_Klienten_Export_${dayjs().format('YYYY-MM-DD')}.csv`;
          saveAs(blob, filename);

          this.$emit('showInfo', {
            message: 'Klienten wurden erfolgreich exportiert.',
            timeout: 5000,
          });

          this.dialog_export_csv = false;
        } catch (error) {
          this.$emit('showError', {
            message: 'Fehler beim Exportieren der Stammdaten. Bitte versuche es erneut. Bei wiederholten auftreten kontaktiere bitte den Support unter contact@zeipsy.com',
            timeout: 10000,
            error: error.message || error,
          });
        } finally {
          this.exporting = false;
        }
      },

      async previewTimeConfirmation(type) {

        if (type === 'default') {
          this.defaultTemplateLoading = true;
          this.current_time_confirmation_template = {
            id: null,
            created_at: null,
            updated_at_formatted: null,
            updated_at: null,
            name: 'appointment_confirmation.docx',
            displayName: 'ZEIPSY-Vorlage',
            size: null,
            mimetype: null,
            uploading: false,
            previewing: false,
            downloading: false,
          };
        } else {
          this.customTemplateLoading = true;
          this.current_time_confirmation_template = this.custom_timeconfirmation_template;
          this.current_time_confirmation_template.displayName = 'Eigene Vorlage';
        }

        try {
          const status = await timeconfirmations.getSampleTimeConfirmation(this, this.current_time_confirmation_template, true, false);
          if ((status && status.status !== "success") || status === null) {
            // silently return if there was an error, the error is already shown
            if (type === 'default') {
              this.defaultTemplateLoading = false;
            } else {
              this.customTemplateLoading = false;
            }
            return;
          }

          if (this.timeConfirmationPreviewUrl) URL.revokeObjectURL(this.timeConfirmationPreviewUrl);
          this.timeConfirmationPreviewUrl = URL.createObjectURL(status.blob);
          this.dialog_time_confirmation_preview = true;
          
        } catch (error) {
          this.$emit('showError', {
            message: 'Fehler beim Generieren der Zeitbestätigungsvorschau. Bitte versuche es erneut.',
            timeout: 7000,
            error: error
          });
        } finally {
          if (type === 'default') {
            this.defaultTemplateLoading = false;
          } else {
            this.customTemplateLoading = false;
          }
        }
      },

      closeTimeConfirmationPreview() {
        this.dialog_time_confirmation_preview = false;
        if (this.timeConfirmationPreviewUrl) URL.revokeObjectURL(this.timeConfirmationPreviewUrl);
        this.timeConfirmationPreviewUrl = null;
        this.current_time_confirmation_template = null;
      },

      async handleTimeConfirmationDownload() {
        let type = 'default';
        if (this.current_time_confirmation_template.updated_at) {
          type = 'custom';
        }
        await this.downloadTimeConfirmationTemplate(type);
      },

      handleTimeConfirmationDrop(e) {
        this.dragOverTimeConfirmation = false;
        let files = e.target.files || e.dataTransfer.files;
        let file = files[0];
        if (file && file.name.endsWith('.docx')) {
          this.timeConfirmationFile = file;
          this.uploadTimeConfirmationTemplate();
        }
      },

      handleTimeConfirmationImport() {
        this.$refs.timeConfirmationUploader.click();
      },

      onTimeConfirmationFileChanged(event) {
        const file = event.target.files[0] || event.dataTransfer.files[0];
        if (file) {
          this.timeConfirmationFile = file;
          this.uploadTimeConfirmationTemplate();
        }
      },

      async uploadTimeConfirmationTemplate() {
        if (!this.timeConfirmationFile) return;

        this.isTimeConfirmationUploading = true;
        
        try {
          let uploaded = await timeconfirmations.uploadTimeConfirmationTemplate(this, this.timeConfirmationFile);
          if (uploaded) {
            this.$emit('showInfo', {
              message: 'Vorlage erfolgreich hochgeladen.',
              timeout: 5000,
            });
          }
          // update template
          this.custom_timeconfirmation_template = await timeconfirmations.getCustomTimeconfirmationTemplate(this);
        } catch (error) {
          this.$emit('showError', {
            message: 'Fehler beim Hochladen der Vorlage. Bitte versuche es erneut.',
            timeout: 7000,
            error: error.message
          });
        } finally {
          this.isTimeConfirmationUploading = false;
          this.$refs.timeConfirmationUploader.value = '';
        }
      },

      async deleteTimeConfirmationTemplate() {

        let deleted = await connector.deleteFileFromBucket(this, 'appointment-templates', this.session.user.id + '/', 'custom-appointment-template.docx');
        if (!deleted) {
          // error has already been shown
          return;
        }

        this.custom_timeconfirmation_template = null;
        this.closeTimeConfirmationPreview();
        this.$emit('showInfo', { 
          message: 'Die Vorlage wurde erfolgreich gelöscht.',
          timeout: 5000 
        });
      },

      async downloadTimeConfirmationTemplate(type) {

        let bucket = 'public-templates';
        let path = 'appointments/';
        let filename = 'appointment_confirmation.docx';

        if (type === 'custom') {
          bucket = 'appointment-templates';
          path = this.session.user.id + '/';
          filename = 'custom-appointment-template.docx';
          
          let updated_at = '?updated_at=' + this.custom_timeconfirmation_template.updated_at;
          filename = filename + updated_at;
        }

        let content = await connector.downloadFile(this, bucket, path, filename, true);
        saveAs(content, "ZEIPSY-Zeitbestätigungsvorlage.docx");
      },

      async changedSchemaInvoiceName() {
        this.updating_schema_invoice_filename = true;

        let updated = await connector.update(this, 'kunden', {
          schema_invoice_filename: this.$store.state.client.schema_invoice_filename
        }, this.session.user.id);
        this.updating_schema_invoice_filename = false;
        if (updated === null) {
          // error has already been shown
          return;
        }
        this.$emit('showInfo', {
          message: 'Das Format für den Dateinamen wurde erfolgreich aktualisiert.',
          timeout: 5000,
        });
      },

      async importSelectedCSVClients() {

        let n_inserted = 0;
        this.importing_clients = true;
        try {

          for (let i = 0; i < this.selected_csv_clients.length; i++) {

            let toSave = Object.assign({}, this.defaultItem);
            toSave.vorname = this.selected_csv_clients[i].vorname;
            toSave.nachname = this.selected_csv_clients[i].nachname;
            toSave.adresse = this.selected_csv_clients[i].adresse;
            toSave.plz = this.selected_csv_clients[i].plz;
            toSave.ort = this.selected_csv_clients[i].ort;
            toSave.svnr = this.selected_csv_clients[i].svnr;
            toSave.email = this.selected_csv_clients[i].email;
            toSave.kommentar = this.selected_csv_clients[i].kommentar;
            toSave.zusatztext = this.selected_csv_clients[i].zusatztext;

            // delete null fields
            for (let key in toSave) {
              if (toSave[key] === null) delete toSave[key]
            }

            let obj = await cipher.encryptObject(this.$store.state.aes_key, toSave);
            let inserted = await connector.insertRow(this, 'klienten', obj);
            if (!inserted) {
              continue;
            }
            n_inserted++;
          }

          // show success message
          this.$emit('showInfo', {
            message: `Es wurden ${n_inserted} von ${this.selected_csv_clients.length} Klienten erfolgreich importiert.`,
            timeout: 5000
          });

          // log to server with imported clients
          connector.logError(this, {
            uid: this.session.user.id,
            message: `Klienten von CSV importiert: ${n_inserted} von ${this.selected_csv_clients.length} Klienten.`,
          });

        } catch (error) {
          this.$emit('showError', {
            message: 'Fehler beim Speichern des Klienten: ' + error,
          });
        }
        this.importing_clients = false;
        this.abortCSVImport();
      },

      abortCSVImport() {
        this.dialog_map_csv_columns = false;
        this.csvData = [];
        this.csvColumns = [];
        this.file = null;
        this.activeStep = 1;
        this.csvFields.forEach((field) => {
          field.csvColumn = null;
          field.csvExampleData = null;
        });
        this.selected_csv_clients = [];
        this.importing_clients = false;
        this.existing_clients = [];
      },

      mapCSVColumns() {
        // iterate over the csvFields and update the csvExampleData according to the selected csvColumn
        this.csvFields.forEach((field) => {
          if (field.csvColumn !== null) {
            field.csvExampleData = this.csvData.slice(0, 4).map((row) => row[field.csvColumn]).join('; ');
          } else {
            field.csvExampleData = null;
          }
        });

      },

      async changeEmail() {
        try {
          const { data, error } = await supabase.auth.updateUser({
            email: this.email
          });
          if (error) {
            if (error.message.includes('A user with this email address has already been registered')) {
              this.$emit('showError', {
                message: 'Mit dieser E-Mail Adresse ist bereits ein Account registriert. Bitte wähle eine andere E-Mail Adresse oder lösche den Account von dieser E-Mail Adresse.',
              });
              return;
            }
            
          } else {
            this.$emit('showInfo', {
              message: 'Bitte überprüfe deine E-Mails und bestätige die Änderung der E-Mail Adresse. Du musst die Änderung sowohl in der alten als auch in der neuen E-Mail Adresse bestätigen.',
              timeout: 5000
            });
          }
        } catch (error) {
          this.$emit('showError', {
            message: 'Fehler beim Ändern der Email-Adresse: ' + error.message,
          });
          console.log(error);
        }
      },

      handleClose(event) {
        // call close method if escape key is pressed
        if (event.code === 'Escape') {
          this.closeInvoicePreview();
        }
      },

      isDefaultTemplate(template) {
        if (this.invoice_templates.length === 0) return false;
         return template.id === this.$store.state.client.standard_vorlage;
      },

      async setAsDefaultTemplate(template) {

        if (template.id === this.$store.state.client.standard_vorlage) {
          return;
        }

        try {
          let data = await connector.updateRow(this, 'kunden', {
            'standard_vorlage': template.id,
          }, this.session.user.id);
          if (data && data.length > 0) {

            let client = await connector.getDataOnly(this, 'vwkunden', 'id', true);
    
            if(client.length > 0) {
              this.$store.commit('setClient', client[0]);
            }

            this.$emit('showInfo', {
              message: 'Rechnungsvorlage erfolgreich als Standard gesetzt.',
              timeout: 5000
            });
          } else {
            this.$emit('showError', {
              message: 'Fehler beim Setzen der Rechnungsvorlage als Standard.',
            });
          }
        } catch (error) {
          this.$emit('showError', {
            message: 'Fehler beim Setzen der Rechnungsvorlage als Standard: ' + error.message,
          });
        }
      },

      bytesToSize(bytes) {
          const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
          if (bytes === 0) return '0 Byte'
          const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
          return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
      },

      closeInvoicePreview() {
          this.dialog_invoice_preview = false;
          if (this.invoice_preview_url) URL.revokeObjectURL(this.invoice_preview_url);
          this.invoice_preview_url = null;
          this.current_invoice_template = null;
      },

      handleInvoiceDownload() {
        if (this.current_invoice_template) {
          this.downloadTemplate(this.current_invoice_template);
        }
      },

      async linkGoogleAccount() {
        const { data, error } = await supabase.auth.linkIdentity({ 
          provider: 'google',
              options: {
                redirectTo: process.env.NODE_ENV === 'development' ? 'http://localhost:8080/kalender' : 'https://app.zeipsy.com/kalender',
                scopes: ['https://www.googleapis.com/auth/calendar.calendarlist.readonly', 'https://www.googleapis.com/auth/calendar.events'],
                queryParams: {
                  access_type: 'offline',
                  prompt: 'consent', // The refresh-token gets returned only immediately after consent. 
                                     // It will not be re-issued on sessionRefresh or Login.        
                                     // Therefore, "force" consent on re-login as refresh-token is not in local storage.
                },
              },
         });

        if (error) {
          localStorage.removeItem('scopes');
          localStorage.removeItem('provider_refresh_token');
          this.$emit('showError', { message: 'Fehler beim Verbinden mit Google.'});
        }
        if (data) {
          localStorage.setItem('scopes', 'https://www.googleapis.com/auth/calendar.calendarlist.readonly,https://www.googleapis.com/auth/calendar.events');
          //localStorage.setItem('provider_refresh_token', data.provider_refresh_token);
          // this will the be set on redirect and then in App.vue on session change.
        }
      },

      async listLinkedIdentities() {

        try {
          // retrieve all identities linked to a user
          let result = await supabase.auth.getUserIdentities();
          if (result && result.data && result.data.identities) {
              
              const {
                  data: { identities },
              } = result;

              const googleIdentity = identities.find((identity) => identity.provider === "google");
              if (googleIdentity) {
                this.linked_google_account_email = googleIdentity.email;
                return true;
              } else {
                this.linked_google_account_email = null;
                return false;
              }
          } else {
              // handle error
              this.$emit("showError", {
                  message: "Fehler beim Abrufen der verknüpften Google-Accounts. Bitte lade die Seite neu.",
                  timeout: 5000,
              });
              this.linked_google_account_email = null;
              return false;
          }
        } catch (error) {
            console.log(error);
            this.$emit("showError", {
                message: "Fehler beim Abrufen der verknüpften Google-Accounts. Bitte lade die Seite neu.",
                timeout: 5000,
            });
            this.linked_google_account_email = null;
            return false;
        }
      },

      async deleteLinkedGoogleAccount() {
        // retrieve all identities linked to a user
        const { data: { identities }, } = await supabase.auth.getUserIdentities();

        // find the google identity linked to the user
        const googleIdentity = identities.find((identity) => identity.provider === 'google');

        // unlink the google identity from the user
        const { data, error } = await supabase.auth.unlinkIdentity(googleIdentity);
        if (error) {
          this.$emit('showError', { message: 'Fehler beim entknüpfen des Google Kontos. Fehlercode: ' + error});
        }
        if (data) {
          localStorage.removeItem('scopes');
          localStorage.removeItem('provider_refresh_token');
          this.linked_google_account = false;
          this.$emit('showInfo', {
             message: 'Google Konto Verknüpfung erfolgreich entfernt.',
              timeout: 5000
            });
        }
      },

      async saveEMailTemplate() {
        try {
          let data = await connector.upsert(this, 'kunden', {
            id: this.session.user.id,
            email_nachricht: this.draft_message,
            email_betreff: this.subject
          });
          if (data) {
            this.saved_email_template = true;
            this.$emit('showInfo', {
              message: 'E-Mail Vorlage erfolgreich gespeichert.',
              timeout: 5000
            });
          } // else {
            // error has already been shown.
          //}

        } catch (error) {
          this.$emit('showError', {
            message: 'Fehler beim Speichern der E-Mail Vorlage. Bitte versuche es erneut.',
            timeout: 8000,
            error: error
          });
        }
      },

      async importAllClients() {

        try {

          for (let i = this.current_client; i < this.therapsy_clients.length; i++) {
            this.current_client = i;
            this.duplicate_client = this.existing_clients.some(client => {
              return client.vorname === this.therapsy_clients[i].vorname && client.nachname === this.therapsy_clients[i].nachname;
            });

            if (this.duplicate_client) continue;

            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedItem.vorname = this.therapsy_clients[i].vorname;
            this.editedItem.nachname = this.therapsy_clients[i].nachname;
            this.editedItem.adresse = this.therapsy_clients[i].adresse;
            this.editedItem.plz = this.therapsy_clients[i].plz;
            this.editedItem.ort = this.therapsy_clients[i].ort;
            this.editedItem.svnr = this.therapsy_clients[i].svnr;
            this.editedItem.email = this.therapsy_clients[i].email;
            this.editedItem.kommentar = this.therapsy_clients[i].kommentar;
            this.editedItem.zusatztext = this.therapsy_clients[i].zusatztext;

            let toSave = Object.assign({}, this.editedItem)
            // delete null fields
            for (let key in toSave) {
              if (toSave[key] === null) delete toSave[key]
            }

            let obj = await cipher.encryptObject(this.$store.state.aes_key, toSave);
            let inserted = await connector.insertRow(this, 'klienten', obj);
            if (!inserted) {
              return;
            }
          }

          this.start_import = false;
          this.dialog_therapsy_import = false;
          this.current_client = 0;

          // show success message
          this.$emit('showInfo', {
            message: 'Klienten erfolgreich importiert.',
            timeout: 5000
          });

          // log to server with imported clients
          connector.logError(this, {
            uid: this.session.user.id,
            message: 'Klienten von TheraPsy importiert (importAll): ' + this.therapsy_clients.length + ' Klienten.',
          });

        } catch (error) {
          this.$emit('showError', {
            message: 'Fehler beim Speichern des Klienten: ' + error,
          });
        }
        
      },

      async startImport() {

        let customers = await connector.getDataOnly(this, 'vwklienten', 'id', true);

        // check if aes key is set
        if (this.$store.state.aes_key === null) {

          // check if the user has already added some clients
          // if so, ask for the safe key
          // else show the dialog to enter a the safe key for the first time
          if (customers.length > 0) {
            this.$emit('showError', {
              message: 'Dein Safe-Schlüssel wird benötigt, um die Klienten zu importieren.',
              additional_button: {
                text: 'Safe-Schlüssel ändern',
                target: '/einstellungen'
              }
            });

            // after that the screen gets reloaded

          } else {
            this.dialog_enter_safe_key = true;
          }
          return;
        }

        this.existing_clients = await cipher.decryptArray(this, customers);

        // check if the first client is already in the database
        let first_client = this.therapsy_clients[0];
        this.duplicate_client = this.existing_clients.some(client => {
          return client.vorname === first_client.vorname && client.nachname === first_client.nachname;
        });

        // set editedItem to first client
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.vorname = this.therapsy_clients[0].vorname;
        this.editedItem.nachname = this.therapsy_clients[0].nachname;
        this.editedItem.adresse = this.therapsy_clients[0].adresse;
        this.editedItem.plz = this.therapsy_clients[0].plz;
        this.editedItem.ort = this.therapsy_clients[0].ort;
        this.editedItem.svnr = this.therapsy_clients[0].svnr;
        this.editedItem.email = this.therapsy_clients[0].email;
        this.editedItem.kommentar = this.therapsy_clients[0].kommentar;
        this.editedItem.zusatztext = this.therapsy_clients[0].zusatztext;

        this.start_import = true;
      },

      nextClient() {
        if (this.current_client < this.therapsy_clients.length - 1) {
          this.current_client++;

          // check if the first client is already in the database
          let this_client = this.therapsy_clients[this.current_client];
          this.duplicate_client = this.existing_clients.some(client => {
            return client.vorname === this_client.vorname && client.nachname === this_client.nachname;
          });

          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedItem.vorname = this.therapsy_clients[this.current_client].vorname;
          this.editedItem.nachname = this.therapsy_clients[this.current_client].nachname;
          this.editedItem.adresse = this.therapsy_clients[this.current_client].adresse;
          this.editedItem.plz = this.therapsy_clients[this.current_client].plz;
          this.editedItem.ort = this.therapsy_clients[this.current_client].ort;
          this.editedItem.svnr = this.therapsy_clients[this.current_client].svnr;
          this.editedItem.email = this.therapsy_clients[this.current_client].email;
          this.editedItem.kommentar = this.therapsy_clients[this.current_client].kommentar;
          this.editedItem.zusatztext = this.therapsy_clients[this.current_client].zusatztext;

        } else {
          this.start_import = false;
          this.dialog_therapsy_import = false;
          this.current_client = 0;

          // show success message
          this.$emit('showInfo', {
            message: 'Klienten erfolgreich importiert.',
            timeout: 5000
          });

          // log to server with imported clients
          connector.logError(this, {
            uid: this.session.user.id,
            message: 'Klienten von TheraPsy importiert: ' + this.therapsy_clients.length + ' Klienten.',
          });
        }
      },

      async save() {
        try {
          if (this.valid) {
            let toSave = Object.assign({}, this.editedItem)
            // delete null fields
            for (let key in toSave) {
              if (toSave[key] === null) delete toSave[key]
            }

            let obj = await cipher.encryptObject(this.$store.state.aes_key, toSave);

            let inserted = await connector.insertRow(this, 'klienten', obj)
            if (inserted) this.nextClient()
          }
        } catch (error) {

          // check if error is a duplicate error (missing not null constraint)

          if ('message' in error) {
              const pattern = /null value in column \"(.*?)\" of relation \"(.*?)\" violates not-null constraint/;
              const matches = error.message.match(pattern);
              if (matches && matches[1] && matches[2]) {
                // already logged & shown
                return;
              }
          }

          this.$emit('showError', {
            message: 'Fehler beim Speichern des Klienten: ' + error,
          });
          
        }
      },

      handleDrop(event) {
        this.dragOver = false;
        this.onFileChanged(event);
      },

      handleFile() {
        if (!this.file) return;

        const reader = new FileReader();

        if (this.file.type === 'text/csv') {

          reader.onload = async (e) => {

            const csv = e.target.result;
            const lines = csv.split('\n');
            if (lines.length === 0) {
              this.$emit('showError', {
                message: 'Die CSV-Datei scheint leer zu sein. Bitte überprüfe die Datei und versuche es erneut.',
              });
              return;
            }

            let delimiter = ';';

            // get the first line and split it by the delimiter
            let columns = lines[0].split(delimiter).map((column) => column.replaceAll('"', '').trim());
            if (columns.length <= 1) {
              // try with comma
              delimiter = ',';
              columns = lines[0].split(delimiter).map((column) => column.replaceAll('"', '').trim());
            }

            if (columns.length <= 1) {
              this.$emit('showError', {
                message: 'Die CSV-Datei scheint keine gültigen Spalten zu haben. Stelle sicher, dass die Datei mit einem Strichpunkt (;) oder Komma (,) getrennt ist und versuche es erneut.',
              });
              return;
            }

            this.csvColumns = columns.map((column, index) => {
              return {
                text: column,
                value: index,
              };
            });

                
            let customers = await connector.getDataOnly(this, 'vwklienten', 'id', true);
            // check if aes key is set
            if (this.$store.state.aes_key === null) {

              // check if the user has already added some clients
              // if so, ask for the safe key
              // else show the dialog to enter a the safe key for the first time
              if (customers.length > 0) {
                this.$emit('showError', {
                  message: 'Dein Safe-Schlüssel wird benötigt, um die Klienten zu importieren.',
                  additional_button: {
                    text: 'Safe-Schlüssel ändern',
                    target: '/einstellungen'
                  }
                });

                // after that the screen gets reloaded

              } else {
                this.dialog_enter_safe_key = true;
              }
              return;
            }

            this.existing_clients = await cipher.decryptArray(this, customers);

            // now map the remaining lines and columns to a dictionary where the key is the column name and the value is the data
            let csvData = lines.slice(1).map((line) => {
              return line.split(delimiter).map((column) => column.replaceAll('"', '').trim());
            });
            this.csvData = csvData;

            // try to map the columns automatically, if the column name is found in the csvFields
            this.csvFields.forEach((field) => {
              let index = columns.findIndex((column) => column.toLowerCase() === field.clientField.toLowerCase());
              if (index !== -1) {
                field.csvColumn = index;
                this.mapCSVColumns();
              }
            });

            this.dialog_map_csv_columns = true;
          };

          reader.readAsText(this.file);
          
        } else {

          reader.onload = (e) => {
            JSZip.loadAsync(e.target.result).then((zip) => {
              const filePromises = Object.keys(zip.files).map((filename) => { // Use map instead of forEach
                if (!filename.startsWith('__MACOSX') && !filename.startsWith('._') && !filename.includes('~$') && filename.endsWith('.xlsx')) {
                  return zip.files[filename].async('arraybuffer').then((content) => { // Return this promise
                    return this.processXlsxFile(content); // Return this promise
                  });
                }
              });

              Promise.all(filePromises).then((clients) => {
                // All therapsy_clients are loaded
                // filter out null values or empty objects or undefined
                clients = clients.filter((client) => client);
                this.therapsy_clients = clients;

                if (this.therapsy_clients.length === 0) {
                  this.$emit('showError', {
                    message: 'Es wurden keine Klienten in der ZIP-Datei gefunden.',
                  });
                  return;
                }

                // open dialog to click through the clients
                this.dialog_therapsy_import = true;
              });
            });
          };

          reader.readAsArrayBuffer(this.file);

        }

      },

      async processXlsxFile(content) {
        try {
          const workbook = new ExcelJS.Workbook();
          await workbook.xlsx.load(content); // Use load method for buffer
          const worksheet = workbook.getWorksheet(1);
          const json = worksheet.getSheetValues();
          const clientInfo = this.parseClientInfo(json);
          if (clientInfo) {
            return clientInfo;
          }
          
        } catch (error) {
          console.log('Error processing Excel file:', error);
          connector.logError(this, {
              uid: this.session.user.id,
              message: 'Error processing Excel file: ' + error,
          });
        }
        return null;
      },

      parseClientInfo(data) {

        if (data.length > 0) {
          try {
            let [therapsy_error, name, address, place, mail, svnr, note, icdCodes] = data;
            let fullname = name['1'];

            let firstname = {};
            let lastname = {};

            if (fullname && Object.keys(fullname).length > 0 && fullname.includes(' ')) {
              firstname = fullname.split(' ')[0];
              lastname = fullname.split(' ').slice(1).join(' ');
            } else {
              lastname = fullname;
            }

            address = address['1'];

            let plz = {};
            let ort = {};
            // check if we can split the place into plz and ort
            // otherwise we just use the whole string as ort

            if (place['1'] && Object.keys(place['1']).length > 0 && place['1'].includes(' ')) {
              plz = place['1'].split(' ')[0];
              ort =  place['1'].split(' ')[1];
            } else {
              ort = place['1'];
            }

            let socialNumber = svnr['1'];
            let email = mail['1'];
            let comment = note['1'];
            let icd = icdCodes['1'];
            let diagnosis = null;

            if (icd && Object.keys(icd).length > 0 && icd.toLowerCase() === 'keine') {
              diagnosis = null;
            } else if (icd && Object.keys(icd).length > 0) {
              diagnosis = 'Diagnose(n): ' + icd;
            }

            return {
              vorname: firstname && Object.keys(firstname).length > 0 ? firstname: null,
              nachname: lastname && Object.keys(lastname).length > 0 ?  lastname: null,
              adresse: address && Object.keys(address).length > 0 ? address: null,
              plz: plz && Object.keys(plz).length > 0 ? plz: null,
              ort: ort && Object.keys(ort).length > 0 ? ort: null,
              svnr: socialNumber && Object.keys(socialNumber).length > 0 ? socialNumber: null,
              email: email && Object.keys(email).length > 0 ? email: null,
              kommentar: comment && Object.keys(comment).length > 0 ? comment: null,
              zusatztext: diagnosis,
            };
          } catch (error) {
            connector.logError(this, {
                uid: this.session.user.id,
                message: 'Error parsing client info: ' + error,
            });
            console.error('Error parsing client info:', error);
          }
          
        }
        return null;
      },

      async initialize() {

        this.email = this.session.user.email;
        this.invoice_templates = await this.getInvoiceTemplates();
        this.custom_timeconfirmation_template = await timeconfirmations.getCustomTimeconfirmationTemplate(this);

        let client = await connector.getDataOnly(this, 'vwkunden', 'id', true);
    
        if(client.length > 0) {
          this.$store.commit('setClient', client[0]);

          // check if email_betreff and email_nachricht are set, if so, use them as default values
          if (this.$store.state.client.email_betreff) this.subject = this.$store.state.client.email_betreff;
          if (this.$store.state.client.email_nachricht) this.draft_message = this.$store.state.client.email_nachricht;
        }

        this.linked_google_account = await this.listLinkedIdentities();

        if (this.$route.query.missing) {
          let required_fields = this.requiredFields;          
          required_fields.forEach(field => {
            this.$refs[field].validate(true);
          });
          if (this.$route.query.missing !== 'passwort-vergessen') {
            this.$refs['praxis_informationen'].scrollIntoView({ behavior: "smooth" });
          }
        } 
      },

      change() {
        this.saved_customer_information = false;
      },

      changePaymentInformation() {
        this.saved_payment_information = false;
      },

      changeInvoiceFormat() {
        this.saved_invoice_format = false;
      },

      changedDefaultValues() {
        this.saved_default_values = false
      },

      changedSafeKey() {
        this.saved_safe_key = false
      },

      changedPassword() {
        this.changed_password = true
        this.updated_password = false
      },

      async updatePassword() {
        let data = await connector.updateUserPassword(this, this.password_zeipsy);
         if (data) {
            this.updated_password = true; 
            this.changed_password = false;
            this.$emit('showInfo', {
              message: 'Das Passwort wurde erfolgreich geändert.',
              timeout: 5000
            });
          } else {
            this.updated_password = false;
          } 
      },

      async save_key() {
        localStorage.data_key = this.$store.state.data_key;

        let keys = await cipher.getAESKeys(this);
        this.$store.state.cache = {};
        this.saved_safe_key = true;

        this.$emit('showInfo', {
          message: 'Der Safe-Schlüssel wurde erfolgreich gespeichert.',
          timeout: 5000
        });
        
      },

      save_safe_key() {
        this.loading_key = true;
        localStorage.data_key = this.$store.state.data_key;

        cipher.getAESKeys(this)
          .then(() => (this.loading_key = false))
          .then(() => window.location.reload(true));

        // also set the last_safe_key_reminder to now minus 15 days, so that the reminder is not shown again immediately
        localStorage.last_safe_key_reminder = dayjs().subtract(15, 'days').toISOString();
      },

      async updatePaymentDateInformation() {
        let data = await connector.upsert(this, 'kunden', {
          id: this.session.user.id,
          zahlungsziel_bank: this.$store.state.client.zahlungsziel_bank,
          zahlungsziel_bar: this.$store.state.client.zahlungsziel_bar,
        });
        
        if (data && data.length > 0) {
          this.saved_payment_information = true;

          this.$emit('showInfo', {
            message: 'Die Daten wurden erfolgreich gespeichert.',
            timeout: 5000
          });

        } else {
          // error has already been shown
          this.saved_payment_information = false;
        }
      },

      async updateInvoiceFormat() {
        let data = await connector.upsert(this, 'kunden', {
          id: this.session.user.id,
          rechnungsnummer_format: this.$store.state.client.rechnungsnummer_format,
        });
        
        if (data && data.length > 0) {
          this.saved_invoice_format = true;

          this.$emit('showInfo', {
            message: 'Die Daten wurden erfolgreich gespeichert.',
            timeout: 5000
          });

        } else {
          // error has already been shown
          this.saved_invoice_format = false;
        }
      },
      
      async save_customer_data() {
        let data = await connector.upsert(this, 'kunden', {
          id: this.session.user.id,
          name: this.$store.state.client.name,
          bezeichnung: this.$store.state.client.bezeichnung,
          telefon: this.$store.state.client.telefon,
          adresse: this.$store.state.client.adresse,
          plz: this.$store.state.client.plz,
          ort: this.$store.state.client.ort,
          iban: this.$store.state.client.iban,
          bic: this.$store.state.client.bic,
          vpnr: this.$store.state.client.vpnr,
        });
        
        if (data && data.length > 0) {
          this.saved_customer_information = true;

          if (this.$route.query.missing) {
            this.$router.push({ path: '/einstellungen' })
          }

          this.$emit('showInfo', {
            message: 'Die Daten wurden erfolgreich gespeichert.',
            timeout: 5000
          });

        } else {
          // error has already been shown
          this.saved_customer_information = false;
        }
      },

      handleFileImport() {
          this.$refs.uploader.click();
      },

      readFileAsync(file) {
        return new Promise((resolve, reject) => {
          // Create a new FileReader object
          const reader = new FileReader()

          // Add an event listener to the reader to handle the load event
          reader.addEventListener('load', () => {
            // Get the ArrayBuffer from the reader result
            const buffer = reader.result

            // Resolve the Promise with the ArrayBuffer
            resolve(buffer);
          })

          // Add an event listener to the reader to handle the error event
          reader.addEventListener('error', () => {
            // Reject the Promise with the error message
            reject(reader.error)
          });

          // Read the contents of the file as an ArrayBuffer
          reader.readAsArrayBuffer(file)
        })
      },

      async onFileChanged(e) {
          let files = e.target.files || e.dataTransfer.files;
          let selectedFile = files[0]
          
          if (selectedFile.size > 1000000) {
              this.$emit('showError', {message: 'Die Datei ist zu groß. Bitte wähle eine Datei mit einer Größe von maximal 1 MB aus. Falls du Bilder in deiner Rechnung hast, füge diese als JPEG hinzu.'});
              this.$refs.uploader.value = ''
              return
          }

          // check if file is docx
          if (selectedFile.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
              this.$emit('showError', {message: 'Die Datei ist kein Word-Dokument. Bitte wähle eine Datei mit der Endung .docx aus.'});
              this.$refs.uploader.value = ''
              return
          }

          this.isUploading = true;

          try {
              // check if file contains at least the following tags using the docxtemplater library and InspectModule
            // nummer, vorname, nachname, adresse, plz, ort, praxis_ort, datum, rechnungs_betrag, termine, praxis_name
            const iModule = InspectModule()
            const buffer = await this.readFileAsync(selectedFile)
            const zip = new PizZip(buffer)
            const doc = new Docxtemplater(zip, {
                paragraphLoop: true,
                linebreaks: true,
                modules: [iModule],
            })

            const tags = iModule.getAllTags()
            let template_tags = Object.keys(tags)
            let required_tags = [
              'nummer', 'datum', 'rechnungs_betrag', 'termine', 'ust_befreiung'
            ]

            let recipient_tags = {
              'empfänger_name': [['empfänger_name'], ['empfänger_vorname', 'empfänger_nachname'], ['vorname', 'nachname']],
              'empfänger_anschrift': [['empfänger_anschrift'], ['empfänger_adresse', 'empfänger_plz', 'empfänger_ort'], ['adresse', 'plz', 'ort']]
            };

            let missing_tags_recipient = invoiceHelper.checkTags(recipient_tags, template_tags);

            let missing_tags = required_tags.filter((tag) => !template_tags.includes(tag));
            missing_tags = missing_tags.concat(missing_tags_recipient);

            if (missing_tags.length > 0) {
                this.$emit('showError', { 
                  message: 'Die Vorlage enthält nicht alle benötigten Pflichtfelder für eine vollständige Rechnung. Bitte füge die folgenden Pflichtfelder in die Vorlage ein: ' + 
                            missing_tags.map((tag) => '{' + tag + '}').join(', ')
                })
                this.isUploading = false
                this.$refs.uploader.value = ''
                return
            }

            let name = selectedFile.name;

            // iterate over each character and check if it is an umlaute, if so replace it with
            for (let i = 0; i < name.length; i++) {
                if (name.charCodeAt(i) === 776) {
                    // umlaute, replace with e
                    name = name.replace(name[i], 'e');
                } else if (name.charCodeAt(i) === 223) {
                    // ß, replace with ss
                    name = name.replace(name[i], 'ss');
                }
            }

            // check if the file name contains special characters, if so, show an error message and skip the file
            if (name.match(/[^a-zA-Z0-9. \-()_]/)) {
                this.$emit('showError', {
                    message: 'Dateinamen dürfen keine Sonderzeichen oder Umlaute enthalten.',
                });
                this.isUploading = false;
                this.$refs.uploader.value = '';
                return;
            }
            
            // check if the file name ends with the .docx extension, if not add it
            if (!name.endsWith('.docx')) {
              name += '.docx';
            }

            let files = await connector.uploadFileToBucket(this, 'invoice-templates', this.session.user.id + '/', name, selectedFile);
            if (files === null) {
              // Error during upload, error has been shown.
              connector.logError(this, {
                  uid: this.session.user.id,
                  message: 'LOG: Possible error during invoice template upload.',
              });
            } else {
              this.$emit('showInfo', {
                message: 'Die Vorlage wurde erfolgreich hochgeladen.',
                timeout: 5000
              });
            }

            this.isUploading = false;
            // clear fileslist in input
            this.$refs.uploader.value = '';
            this.invoice_templates = await this.getInvoiceTemplates();
            return;
          }
          catch (error) {
              let message = 'Die Vorlage konnte nicht gelesen werden. Vermutlich enthält die Vorlage fehlerhafte Platzhalter. Wende dich per E-Mail an contact@zeipsy.com für Unterstützung.'
              // check if error contains prop error, and if the prop is an array, if so add the first error message to the message
              if (error.properties && Array.isArray(error.properties.errors) && error.properties.errors.length > 0) {
                  message += ' Fehler: ' + error.properties.errors[0].message
              }
              this.$emit('showError', {message: message})
              this.isUploading = false;
              this.$refs.uploader.value = ''
          }
          
      },

      async getInvoiceTemplates() {

        let templates = [{
          id: null,
          created_at: null,
          updated_at_formatted: null,
          updated_at: null,
          name: 'invoice-template-with-services-and-ust.docx',
          displayName: 'ZEIPSY-Vorlage',
          size: null,
          mimetype: null,
          uploading: false,
          previewing: false,
          downloading: false,
        }];

        try {
          let files = await connector.listFilesInBucket(this, 'invoice-templates', this.session.user.id + '/');
          if (files === -1) {
            // error has already been shown
            files = [];
          }
          let filtered_files = [...files.filter(file => file.id !== null && file.name !== '.emptyFolderPlaceholder')]
          filtered_files = filtered_files.map((file) => {
            file.updated_at_formatted = dayjs(file.updated_at).format('DD.MM.YY HH:mm');
            file.updated_at = dayjs(file.updated_at).toISOString();
            if (file.name === 'custom-invoice-template-with-services-and-ust.docx') {
              file.displayName = 'Eigene-Vorlage';
            } else {
              // remove the .docx extension if it ends with it
              file.displayName = file.name.endsWith('.docx') ? file.name.slice(0, -5) : file.name;
            }
            file.size = this.bytesToSize(file.metadata.size);
            file.uploading = false;
            file.previewing = false;
            file.downloading = false;
            return file;
          }).filter((file) => file.name !== 'custom-invoice-template.docx');
          return templates.concat(filtered_files);
        } catch (error) {
          this.$emit('showError', { message: 'Fehler beim Laden der Vorlagen: ' + error });
          return templates;
        }
      },

      async previewInvoice(invoice_template) {
        try {
          invoice_template.previewing = true;
          const status = await invoiceHelper.getSampleInvoicePDF(this, invoice_template);
          if ((status && status.status !== "success") || status === null) {
            // silently return if there was an error, the error is already shown
            invoice_template.previewing = false;
            return;
          }
          if (this.invoice_preview_url) URL.revokeObjectURL(this.invoice_preview_url);
          this.invoice_preview_url = URL.createObjectURL(status.blob);
          this.current_invoice_template = invoice_template; 
          this.dialog_invoice_preview = true;
          
        } catch (error) {
          this.$emit('showError', {
            message: 'Fehler beim Generieren der Vorschau. Bitte versuche es erneut.',
            timeout: 7000,
            error: error
          });
        } finally {
          invoice_template.previewing = false;
        }
      },

      async downloadTemplate(invoice_template) {
        if (invoice_template.name === 'invoice-template-with-services-and-ust.docx' && invoice_template.displayName === 'ZEIPSY-Vorlage') {
          // download the standard template
          let content = await connector.downloadFile(this, 'public-templates', 'invoices/', 'invoice-template-with-services-and-ust.docx', true);
          saveAs(content, "ZEIPSY-Vorlage.docx");
        } else {
          let updated_at = '?updated_at=' + invoice_template.updated_at;
          let name = invoice_template.name + updated_at;
          let content = await connector.downloadFile(this, 'invoice-templates', this.session.user.id + '/', name, true);
          saveAs(content, invoice_template.displayName + '.docx');
        }
      },

      async deleteTemplate(invoice_template) {
        if (invoice_template.name === 'invoice-template-with-services-and-ust.docx' && invoice_template.displayName === 'ZEIPSY-Vorlage') {
          this.$emit('showError', { message: 'Die ZEIPSY-Vorlage kann nicht gelöscht werden.', timeout: 5000 });
          return;
        }

        let deleted = await connector.deleteFileFromBucket(this, 'invoice-templates', this.session.user.id + '/', invoice_template.name);
        if (!deleted) {
          // error has already been shown
          return;
        }
        await this.initialize();
        this.closeInvoicePreview();
        this.$emit('showInfo', { message: 'Die Vorlage wurde erfolgreich gelöscht.', timeout: 5000 });
      },
      
      async delete_data() {
        this.deleting = true;

        let deleted = await connector.deleteAllUserFilesInBucket(this, 'documentation', this.session.user.id);

        await connector.deleteAll(this, 'termine', 'uid', this.session.user.id);
        await connector.deleteAll(this, 'buchungen', 'uid', this.session.user.id);
        await connector.deleteAll(this, 'buchungstermine', 'uid', this.session.user.id);

        // check if user has profile id
        let profiles = await connector.getDataOnly(this, 'buchungsprofile', 'id');
        if (profiles === -1) {
          // error has already been displayed
          return;
        } else if (profiles.length > 0) {
          await connector.deleteAll(this, 'buchungsanfragen', 'fk_profil_id', profiles[0].profil_id);
        }
        
        await connector.deleteAll(this, 'buchungsprofile', 'id', this.session.user.id);
        await connector.deleteAll(this, 'dokumentationsvorlagen', 'uid', this.session.user.id);
        await connector.deleteAll(this, 'rechnungen', 'uid', this.session.user.id);
        await connector.deleteAll(this, 'dienstleistungen', 'uid', this.session.user.id);
        await connector.deleteAll(this, 'transaktionen', 'uid', this.session.user.id);
        await connector.deleteAll(this, 'verpflichtungen', 'uid', this.session.user.id);
        await connector.deleteAll(this, 'konten', 'uid', this.session.user.id);
        await connector.deleteAll(this, 'klienten_merkmale', 'uid', this.session.user.id);
        await connector.deleteAll(this, 'merkmale', 'uid', this.session.user.id);
        await connector.deleteAll(this, 'klienten', 'uid', this.session.user.id);
        await connector.deleteAll(this, 'institutionen', 'uid', this.session.user.id);
        await connector.deleteAll(this, 'privattermine', 'uid', this.session.user.id);

        deleted = await connector.deleteAllUserFilesInBucket(this, 'invoice-templates', this.session.user.id);

        this.$store.state.cache = {};
        this.$store.commit('resetOnboardingStatus');
        await this.initialize();

        this.dialog = false;
        this.deleting = false;
        this.$emit('showInfo', {
          message: 'Die Daten wurden erfolgreich gelöscht.',
          timeout: 5000
        });
        
      }
    }
  }
</script>
  
<style scoped>
/* Mimic v-textarea styling */
.preview-textarea {
  white-space: pre-wrap;
  padding: 16px 12px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-top: 8px; /* Add some space between text areas */
  min-height: 100px; /* Give it a minimum height */
  overflow-y: auto; /* In case content is too long */
}
.file-card {
    transition: box-shadow .3s ease;
    /* cursor: pointer; */
    /* position: relative; */
    /* Ensure the positioning context for the speed dial */
}

.v-sheet.v-card {
  border-radius: 6px;
}

.active {
  border: 2px solid #00a152 !important;
}

.file-info-container {
    min-width: 0;
    /* This ensures that the container can shrink as needed */
}

.text-truncate-file {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.upload-area {
    border: dashed 2px lightgrey !important;
    border-radius: 20px !important;
    text-align: center !important;
}
</style>