import { Buffer } from 'buffer'
import connector from './supabase-connector'

export default {

    async encryptFileWithKey(arrayBuffer, keyString) {

      const salt = window.crypto.getRandomValues(new Uint8Array(16));
      let passwordKey = await this.getPasswordKey(keyString);
      let aesKey = await this.deriveKey(passwordKey, ['encrypt'], salt);

      const iv = window.crypto.getRandomValues(new Uint8Array(16));
      const encrypted = await window.crypto.subtle.encrypt(
        { 
          name: "AES-GCM", 
          iv: iv 
        },
        aesKey,
        arrayBuffer
      );
    
      let encrypted_data = {
        salt: Buffer.from(salt).toString("base64").replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''),
        iv: Buffer.from(iv).toString("base64"),
        data: Buffer.from(encrypted).toString("base64"),
        version: 1
      };

      return encrypted_data;
    },

    async decryptBookingDetails(encryptedDetails, encryptionKey) {
      try {
        const { iv, data } = JSON.parse(encryptedDetails);
        const decodedData = Buffer.from(data, 'base64');
        const decodedIv = Buffer.from(iv, 'base64');

        const key = await window.crypto.subtle.importKey(
          "jwk",
          JSON.parse(encryptionKey),
          { name: "AES-GCM" },
          false,
          ["decrypt"]
        );

        const decryptedData = await window.crypto.subtle.decrypt(
          {
            name: "AES-GCM",
            iv: decodedIv,
          },
          key,
          decodedData
        );

        return JSON.parse(new TextDecoder().decode(decryptedData));
      } catch (error) {
        console.error("Error decrypting booking details:", error);
        return null;
      }
    },

    decryptDataAsync(component, data, target, progress_indicator) {
      if (data === -1) {
        // wrong key
        component[target] = []
      }
      else {
        component[target] = []
        data.map((obj) => this.decryptObject(component, component.$store.state.aes_key, obj).then((dec) => component[target].push(dec)))
        component[progress_indicator] = false
      }
    },

    async decryptDataSync(component, data) {
      if (data === -1) {
        // wrong key
        return [];
      }
      else {
        let dec_customers = data.map(async (obj) => this.decryptObject(component, component.$store.state.aes_key, obj));
        return Promise.all(dec_customers);
      }
    },

    decryptArray(component, data, salted=false) {
      if (data === -1) {
        // wrong key
        return []
      }
      else {
        let key = component.$store.state.aes_key;
        if (salted) {
          key = component.$store.state.aes_key_file;
        }
        return Promise.all(data.map((obj) => this.decryptObject(component, key, obj)))
      }
    },

    async getAESKeys(component) {

      let data_key = component.$store.state.data_key;
      if (!data_key) {
        // trigger safe key input dialog
        component.$emit('showError', {
          message: 'Daten konnten nicht entschlüsselt werden. Bitte überprüfe den Safe-Schlüssel.',
          additional_button: {
            text: 'Safe-Schlüssel ändern',
            target: '/einstellungen'
          }
        })
        return {
          aes_key: null,
          aes_key_file: null
        };
      }

      // try to retrieve salt from DB
      let client = await connector.getDataOnly(component, 'vwkunden', 'id', true);
      if (client === -1) {
        // error has already been shown

      } else if (client.length > 0) {
        component.$store.commit('setClient', client[0]);
      } else {
        connector.logError(component, {
          uid: component.$store.state.client.id,
          message: 'Konnte Salt nicht aus Datenbank laden.'
        });
        console.error('Konnte Salt nicht aus Datenbank laden.');
      }

      // check if salt is in local storage, if so, set it to store if not already set
      // if (localStorage.getItem('salt') && !component.$store.state.client.salt) {
      //   console.log('Salt found in local storage, setting to store as it is not set yet');
      //   component.$store.state.client.salt = localStorage.getItem('salt');
      // }

      // check if the client has a salt, if not, load it from the database
      // if (!component.$store.state.client.salt && component.$store.state.session) {
      //   // load salt from database in vwkunden
      //   let client = await connector.getDataOnly(component, 'vwkunden', 'id', true);

      //   if (client.length > 0) {
      //     component.$store.commit('setClient', client[0]);
      //   } else {
      //     connector.logError(component, {
      //       uid: component.$store.state.client.id,
      //       message: 'Konnte Salt nicht aus Datenbank laden.'
      //     });
      //   }
      // } 
      // else if (component.$store.state.session === null) {
      //   // user has no salt, but is also not logged in
      //   console.log('User is not logged in, no salt available');
      //   return {
      //     aes_key: null,
      //     aes_key_file: null
      //   }
      // }

      let passwordKey = await this.getPasswordKey(data_key);
      let aesKey = await this.deriveKey(passwordKey, ['encrypt', 'decrypt']);
      let aesKeySalt = component.$store.state.client.salt ? await this.deriveKey(passwordKey, ['encrypt', 'decrypt'], component.$store.state.client.salt) : null;

      // component.$store.state.aes_key = aesKey;
      // component.$store.state.aes_key_file = aesKeySalt;

      component.$store.commit('setAesKey', aesKey);
      component.$store.commit('setAesKeyFile', aesKeySalt);

      return {
        aes_key: aesKey,
        aes_key_file: aesKeySalt
      }
    
    },

    async getPasswordKey (password) {
        let enc = new TextEncoder();
        return window.crypto.subtle.importKey("raw", enc.encode(password), "PBKDF2", false, ["deriveKey"])
    },

    async deriveKey (passwordKey, keyUsage, salt = null) {
        // if a salt is provided, decode it from base64
        let decoded_salt = new Uint8Array(16);
        if (salt) {
          decoded_salt = new Uint8Array(Buffer.from(salt, 'base64'));
        }

        return window.crypto.subtle.deriveKey(
          {
            name: "PBKDF2",
            salt: decoded_salt,//window.crypto.getRandomValues(new Uint8Array(16)),
            iterations: 250000,
            hash: "SHA-512",
          },
          passwordKey,
          { name: "AES-GCM", length: 256 },
          false,
          keyUsage
        )
      },

      async decryptData(encryptedData, aesKey) {
        const encryptedDataBuff = encryptedData;
        const iv = encryptedDataBuff.slice(0, 16);
        const data = encryptedDataBuff.slice(16);

        const decryptedContent = await window.crypto.subtle.decrypt(
          {
            name: "AES-GCM",
            iv: iv, //The same iv you used to encrypt
            length: 128, //The same length you used to encrypt
          },
          aesKey,
          data
        );
        return new TextDecoder().decode(decryptedContent)
      },

      async encryptData(secretData, aesKey) {
        // try {
          let iv = window.crypto.getRandomValues(new Uint8Array(16))
          const encryptedContent = await window.crypto.subtle.encrypt(
            {
              name: "AES-GCM",
              iv: iv,
              length: 128, //can be 1-128
            },
            aesKey,
            new TextEncoder().encode(secretData)
          )
          let encryptedContentArr = new Uint8Array(encryptedContent)
          let buff = new Uint8Array(
            iv.byteLength + encryptedContentArr.byteLength
          );
          buff.set(iv, 0)
          buff.set(encryptedContentArr, iv.byteLength)
          
          return '\\x' + Buffer.from(buff).toString('hex')

        // } catch (e) {
          // console.log(`Error - ${e}`);
          // return "";
        // }
    },

    async encryptObject(aesKey, objectToEncrypt) {
        const encryptedObject = {};
      
        // Encrypt each attribute of the object separately
        for (const [key, value] of Object.entries(objectToEncrypt)) {
          // check if value is a number

            if (key === "uid" || key === "id" || key === 'rechnungs_empfänger' || key === 'mitversichert_bei' || value === null || typeof value === 'boolean' || typeof value === 'number' || key.startsWith('fk_')) {
                encryptedObject[key] = value
            } else {
                encryptedObject[key] = await this.encryptData(value, aesKey)
            }
        }
      
        return encryptedObject
      },

      async encryptFile(aesKey, file) {
        const iv = window.crypto.getRandomValues(new Uint8Array(16));
        const encryptedFile = await window.crypto.subtle.encrypt(
          {
            name: "AES-GCM",
            iv: iv,
            length: 128, //can be 1-128
          },
          aesKey,
          file
        );
        return ({ 
          iv: Buffer.from(iv).toString('base64'), 
          file: Buffer.from(encryptedFile).toString('base64')
        });
      },

      async decryptFile(aesKey, file) {
        const iv = Buffer.from(file.iv, 'base64');
        const fileData = Buffer.from(file.file, 'base64');
        const decryptedFile = await window.crypto.subtle.decrypt(
          {
            name: "AES-GCM",
            iv: iv,
            length: 128, //can be 1-128
          },
          aesKey,
          fileData
        );
        return decryptedFile;
      },
      
      async decryptObject(component, aesKey, encryptedObject, emitError = true) {
        const decryptedObject = {};

        // if (!(aesKey instanceof CryptoKey)) {
        //   component.$emit('showError', {
        //     message: 'Safe-Schlüssel wurde nicht gefunden. Bitte überprüfe den Safe-Schlüssel in den Einstellungen.',
        //     additional_button: {
        //       text: 'Safe-Schlüssel ändern',
        //       target: '/einstellungen'
        //     }
        //   });
        //   return decryptedObject;
        // }

        // Decrypt each attribute of the object separately
        for (const [key, value] of Object.entries(encryptedObject)) {
            if (key === "uid" || key === "id" || value === null || typeof value !== 'string' || !value.startsWith('\\x')) {
                decryptedObject[key] = value
            } else {
                if (value in component.$store.state.cache) {
                  decryptedObject[key] = component.$store.state.cache[value]
                } else {
                  try {
                    decryptedObject[key] = await this.decryptData(Uint8Array.from(Buffer.from(value.substring(2), "hex")), aesKey)
                    component.$store.state.cache[value] = decryptedObject[key]
                  } catch (e) {
                    if (emitError) {
                      component.$emit('showError', {
                        message: 'Daten konnten nicht entschlüsselt werden. Bitte überprüfe den Safe-Schlüssel.',
                        additional_button: {
                          text: 'Safe-Schlüssel ändern',
                          target: '/einstellungen'
                        }
                      })
                    }
                    
                    decryptedObject[key] = "********"
                  }
                }
            }
        }
        return decryptedObject;
      },

      async encryptFileName(component, aesKey, fileName) {
        try {
          // Generate a 16-byte IV
          const iv = window.crypto.getRandomValues(new Uint8Array(16));
          // Encode the file name
          const encodedFileName = new TextEncoder().encode(fileName);
          // Encrypt using AES-GCM
          const encrypted = await window.crypto.subtle.encrypt(
            { name: "AES-GCM", iv },
            aesKey,
            encodedFileName
          );
          // Combine IV and ciphertext into one Uint8Array
          const encryptedArray = new Uint8Array(encrypted);
          const combined = new Uint8Array(iv.byteLength + encryptedArray.byteLength);
          combined.set(iv);
          combined.set(encryptedArray, iv.byteLength);
          // Convert to base64 and then make URL safe
          let base64String = Buffer.from(combined).toString('base64');
          base64String = base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
          // Return the encrypted file name with a marker
          return "enc:1:" + base64String;
        } catch (error) {
          // error during encryption
          connector.logError(component, {
            uid: component.$store.state.client.id,
            message: 'Konnte Dateiname nicht verschlüsseln. ' + error
          });
          return fileName;
        }
      },
    
      async decryptFileName(component, aesKey, encryptedFileName) {
        // If the file name does not start with the marker, assume it is not encrypted
        if (!encryptedFileName.startsWith("enc:1:")) {
          return encryptedFileName;
        }
        try {
          // Remove the marker
          const base64String = encryptedFileName.slice(6);
          // Convert URL-safe base64 back to standard base64 (re-add padding if needed)
          let b64 = base64String.replace(/-/g, '+').replace(/_/g, '/');
          while (b64.length % 4) {
            b64 += '=';
          }
          const combined = Uint8Array.from(Buffer.from(b64, 'base64'));
          // Extract the IV (first 16 bytes)
          const iv = combined.slice(0, 16);
          // The rest is the encrypted data
          const encryptedData = combined.slice(16);
          // Decrypt using AES-GCM
          const decrypted = await window.crypto.subtle.decrypt(
            { name: "AES-GCM", iv },
            aesKey,
            encryptedData
          );
          return new TextDecoder().decode(decrypted);
        } catch (error) {
          // error during decryption
          connector.logError(component, {
            uid: component.$store.state.client.id,
            message: 'Konnte Dateiname nicht entschlüsseln. ' + error
          });
          return "Unbekannter Dateiname";
        }
        
      },

}