import { render, staticRenderFns } from "./Kalender.vue?vue&type=template&id=74cd1179&scoped=true"
import script from "./Kalender.vue?vue&type=script&lang=js"
export * from "./Kalender.vue?vue&type=script&lang=js"
import style0 from "./Kalender.vue?vue&type=style&index=0&id=74cd1179&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74cd1179",
  null
  
)

export default component.exports