<template>
    <v-dialog eager v-model="dialog_send_invoice" fullscreen hide-overlay persistent>
        <v-card>
            <v-toolbar dark :color="$store.state.theme.primary">
                <v-btn icon dark @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Rechnungsversand</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="mt-5">
                <InvoiceSendEmail
                    ref="send_email_component"
                    :session="session"
                    abortName="Abbrechen"
                    @close="closeDialog"
                    @showError="$emit('showError', $event)"
                    @showInfo="$emit('showInfo', $event)"
                    @loadInvoices="$emit('loadInvoices')"
                />
            </v-card-text>    
        </v-card>
    </v-dialog>
</template>

<script>
import InvoiceSendEmail from './InvoiceSendEmail.vue';

export default {
    emits: ["showError", "showInfo", "loadInvoices"],
    props: ["session"],

    components: {
        InvoiceSendEmail
    },

    data() {
        return {
            dialog_send_invoice: false,
        }
    },

    methods: {
        async openSendEmail(item, invoice_template) {
            const result = await this.$refs.send_email_component.openSendEmail(item, invoice_template);
            if (result.status === "success") {
                this.dialog_send_invoice = true;
            }
            return result;
        },

        close() {
            this.$refs.send_email_component.closeSendEmail();
        },

        closeDialog() {
            this.dialog_send_invoice = false;
        }
    }
}
</script>
